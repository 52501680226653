import React from 'react'
import "../UpgradePackage.scss"
import { upgradePackageStore } from '../UpgradePackageStore';
import { observer } from 'mobx-react';
import UpgradeFarmModal from './UpgradeFarmModal';
import { useTranslation } from 'react-i18next';
import { Statistic } from 'antd';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
dayjs.extend(utc);

function NotificationExpired({ data }: any) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    let packageName = localStorage.getItem("packageName");
    let farmOwner = localStorage.getItem("farmOwner");

    const targetTimestamp = Number(localStorage.getItem("expirationTime") || 0);
    const now = Date.now();
    const remainingTime = targetTimestamp - now;

    const onFinish = () => {
        navigate("het-han-dung-thu")
    };

    return (
        <>
            <div className='notification-expired'>
                {
                    data?.isExpired
                        ? <span>{t("notificationExpired.notifi_4_1")} {packageName} {t("notificationExpired.notifi_4_2")}</span>
                        : <span className='d-flex align-items-center h-48px'>
                            <span className='line-height-48px h-48px pr-4px'>
                                {t("notificationExpired.notifi_1_1")}
                                <span className='text-lowercase'>{packageName}</span>
                                {t("notificationExpired.notifi_1_2")}
                            </span>
                            <Statistic.Countdown
                                value={targetTimestamp}
                                onFinish={onFinish}
                                format={remainingTime > 24 * 60 * 60 * 1000 ? 'D ngày HH giờ mm phút ss giây.' : 'HH giờ mm phút ss giây.'}
                            />
                        </span>
                }
                <span>{t("notificationExpired.notifi_3")}</span>
                {
                    farmOwner &&
                    <button     
                        className="btn button_add text-white"
                        data-toggle="modal"
                        data-target={`#upgrade-farm-modal`}
                        onClick={() => {
                            upgradePackageStore.isOpenHistory = false
                            upgradePackageStore.upgradeNow()
                        }}
                    >
                        {t("upgradeNow")}
                    </button>
                }
            </div>
            <UpgradeFarmModal />
        </>
    )
}

export default observer(NotificationExpired)