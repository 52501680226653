import { observable } from 'mobx'
import $ from 'jquery'
import { packageService } from "./PackageService";
import { toastUtil } from "../../../common/utils/ToastUtil";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import { checkLength } from "../../../common/utils/Utils";

interface IError {
    name?: string,
    limitUser?: string,
    giftQRBatchQuantity?: string,
    giftQRProductQuantity?: string,
}
interface IDataRequest {
    id: string | number,
    name: string,
    type: string,
    limitUser: string | number,
    giftQRBatchQuantity?: string | number,
    giftQRProductQuantity?: string | number,
    isActive: boolean,
    unlimitedUser: boolean,
    isRecommended: boolean,
    exportExcel: boolean,
    exportQRProduct: boolean,
    exportQrBatchUnlimited: boolean,
    exportQrBatch: boolean,
    fraudAlert: boolean,
    isAddPackages?: boolean,
    packagePrices: {
        id?: string | number
        price?: number | string,
        validityPeriod: number | string,
        timeUnit?: string,
        errorPrice?: string,
        errorValidityPeriod: string,
    }[],
}

export enum TypeTime {
    PAID = "PAID",
    TRIAL = "TRIAL"
}

export enum TypeDate {
    YEARS = "YEARS",
    DAYS = "DAYS"
}

class PackageStore {
    @observable id: number | string = ''
    @observable page: number = 0
    @observable name: string = ''
    @observable isActive: boolean = false
    @observable totalPage: number = 0
    @observable listPackage: any[] = []
    @observable errors: IError = {
        name: '',
        limitUser: '',
        giftQRBatchQuantity: '',
        giftQRProductQuantity: '',
    }
    @observable dataRequest: IDataRequest = {
        id: '',
        name: '',
        type: TypeTime.PAID,
        limitUser: 0,
        giftQRBatchQuantity: 0,
        giftQRProductQuantity: 0,
        isActive: true,
        unlimitedUser: false,
        isRecommended: false,
        exportExcel: false,
        exportQRProduct: false,
        exportQrBatchUnlimited: false,
        exportQrBatch: false,
        fraudAlert: false,
        packagePrices: [
            {
                id: '',
                price: 0,
                validityPeriod: 1,
                errorPrice: "",
                errorValidityPeriod: "",
            }
        ],
    }
    @observable isOpenDetail: boolean = false
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable isCloseAddPackages: boolean = false


    clearError() {
        this.errors = {
            name: '',
            limitUser: '',
            giftQRBatchQuantity: '',
            giftQRProductQuantity: '',
        }
    }

    clearForm() {
        this.clearError()
        this.dataRequest = {
            id: '',
            name: '',
            type: TypeTime.PAID,
            limitUser: 0,
            giftQRBatchQuantity: 0,
            giftQRProductQuantity: 0,
            isActive: true,
            unlimitedUser: false,
            isRecommended: false,
            exportExcel: false,
            exportQRProduct: false,
            exportQrBatchUnlimited: false,
            exportQrBatch: false,
            fraudAlert: false,
            packagePrices: [
                {
                    id: '',
                    price: 0,
                    validityPeriod: 1,
                    errorPrice: "",
                    errorValidityPeriod: "",
                }
            ]
        }
        this.isCloseAddPackages = false;
    }

    async getPackage() {
        this.isLoading = true;
        const result = await packageService.getPackage()
        this.isLoading = false;
        if (result.status === 200) {
            this.listPackage = result.body.data;
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: any) {
        this.clearError()
        const result = await packageService.getDetail(id);
        if (result.status === 200) {
            this.dataRequest = result.body;
            if (!checkLength(this.dataRequest.packagePrices)) {
                this.dataRequest.packagePrices = [
                    {
                        id: '',
                        price: 0,
                        validityPeriod: this.dataRequest.type === TypeTime.PAID ? 1 : 7,
                        errorPrice: "",
                        errorValidityPeriod: "",
                    }
                ]
            }
            this.dataRequest.isAddPackages = false;
            this.dataRequest.giftQRBatchQuantity = result.body.giftQRCodeExportBatch?.qrCodeLimit || 0
            this.dataRequest.giftQRProductQuantity = result.body.giftQRCodeExportProduct?.qrCodeLimit || 0
        }
    }


    async changeState(id: number, isActive: boolean) {
        this.isLoadingBt = true
        const result = await packageService.changeState(id, !isActive)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${isActive ? 'Ngưng' : 'Bật'} hoạt động thiết lập gói thành công`)
            this.listPackage.map((item: any) => {
                if (item.id === id) {
                    item.isActive = !isActive
                }
            })
        } else toastUtil.error(result.body.message)
    }

    async changeRecommended(id: number, isRecommended: boolean) {
        this.isLoadingBt = true
        const result = await packageService.changeRecommended(id, !isRecommended)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Thay đổi gói khuyên dùng thành công`, 1)
            this.listPackage.map((item: any) => {
                item.isRecommended = item.id === id
            })
        } else toastUtil.error(result.body.message)

    }

    async addEdit() {
        this.clearError()

        let {
            id,
            name,
            isActive,
            limitUser,
            type,
            exportExcel,
            exportQRProduct,
            isRecommended,
            unlimitedUser,
            packagePrices,
            giftQRBatchQuantity,
            giftQRProductQuantity,
            exportQrBatchUnlimited,
            exportQrBatch,
            fraudAlert,
        } = this.dataRequest

        let error = false

        if (!name?.trim()) {
            error = true
            this.errors.name = 'Tên gói không được để trống!'
        }
        if (!limitUser && !unlimitedUser) {
            error = true
            this.errors.limitUser = 'Số lượng người dùng không được để trống!'
        }

        let arrPackagePrices: any[] = packagePrices && packagePrices.map((item: any) => {
            if (!Number(item.validityPeriod)) {
                error = true
                item.errorValidityPeriod = 'Không được để trống!'
            }
            if (type === TypeTime.PAID) {
                if (!item.price) {
                    error = true
                    item.errorPrice = "Không được để trống"
                }
                if (item.price && item.validityPeriod) {
                    return {
                        id: item.id,
                        price: item.price,
                        validityPeriod: item.validityPeriod,
                        timeUnit: TypeDate.YEARS
                    }
                }
            } else {
                if (item.validityPeriod) {
                    return {
                        id: item.id,
                        validityPeriod: item.validityPeriod,
                        timeUnit: TypeDate.DAYS
                    }
                }
            }
        })

        if (error) {
            return;
        }

        let data = {
            name: name,
            type: type,
            isActive: type === TypeTime.PAID ? isActive : true,
            limitUser: limitUser,
            exportExcel: exportExcel,
            exportQRProduct: exportQRProduct,
            unlimitedUser: unlimitedUser,
            isRecommended: type === TypeTime.PAID ? isRecommended : false,
            packagePrices: arrPackagePrices,
            giftQRBatchQuantity: giftQRBatchQuantity,
            giftQRProductQuantity: giftQRProductQuantity,
            exportQrBatchUnlimited: exportQrBatchUnlimited,
            exportQrBatch: exportQrBatch,
            fraudAlert: fraudAlert,
        }

        this.isLoadingBt = true
        const result = id ? await packageService.edit(id, data) : await packageService.add(data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${id ? "Cập nhật" : 'Thiết lập'} thiết lập gói thành công`, 2);

            if (this.dataRequest.isAddPackages) {
                this.clearForm()
                this.isCloseAddPackages = true;
                this.dataRequest.isAddPackages = true;
            } else {
                this.clearForm()
                $('#close_package').trigger('click')
                packageStore.getPackage();
            }
        } else {
            if (result.body.message?.validityPeriodUnique) {
                toastUtil.warning(result.body.message?.validityPeriodUnique, 1)
            } else {
                toastUtil.error(result.body.message)
            }
        }
    }


    async delete() {
        this.isLoadingBt = true
        const result = await packageService.delete(this.id)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Xóa thiết lập gói thành công`, 1)
            $('#close_delete').trigger('click')
            this.getPackage().then()
        } else toastUtil.error(result.body.message)

    }
}

export const packageStore = new PackageStore();


