/* eslint-disable array-callback-return */
import { observable } from "mobx";
import { upgradePackageService } from "./UpgradePackageService";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import { TIMES, TYPE_QR_PACKAGE } from "./UpgradePackageConstant";
import { toastUtil } from "../../common/utils/ToastUtil";
import $ from "jquery";
import { IPackage, IPackagePriceConvert, IQRCodePackage } from "./InterfaceUpgradePackage";
import { historyOrderFarmStore } from "../historyOrderFarm/HistoryOrderFarmStore";

class UpgradePackageStore {
    @observable tabActive: any = ''
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable statusPackageFarm: string = ""
    @observable isOpenHistory: boolean = false
    @observable farmPackageBuyInfo: any = {}
    @observable listFarmPackage: IPackage[] = []
    @observable listFarmPackagePrice: IPackagePriceConvert[] = []
    @observable listQrCodeProduct: IQRCodePackage[] = []
    @observable listQrCodeBatches: IQRCodePackage[] = []
    @observable requestListData: any = []
    @observable params: any = {
        validityPeriod: null
    }

    async getFarmPackage(size?: number) {
        this.isLoading = true
        const result = await upgradePackageService.getFarmPackage()
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listFarmPackage = result.body.data
        }
    }

    async getFarmPackageBuyInfo() {
        const result = await upgradePackageService.getFarmPackageBuyInfo()
        if (result.status === HttpStatusCode.OK) {
            this.farmPackageBuyInfo = result.body
        } else toastUtil.error(result.body.message)
    }

    async getQrCodeProduct() {
        this.isLoading = true
        const result = await upgradePackageService.getQrCodePackage(TYPE_QR_PACKAGE.PACKAGE_EXPORT_PRODUCT, true)
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listQrCodeProduct = result.body.data
        }
    }

    async getQrCodeBatches() {
        this.isLoading = true;
        const result = await upgradePackageService.getQrCodePackage(TYPE_QR_PACKAGE.PACKAGE_EXPORT_BATCH, true)
        this.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            this.listQrCodeBatches = result.body.data
        }
    }

    clearParam() {
        upgradePackageStore.params = {
            validityPeriod: null
        }
    }

    clearForm() {
        this.listFarmPackage = [];
        this.listQrCodeProduct = [];
        this.listQrCodeBatches = [];
        this.requestListData = [];
    }

    convertDataSelectAnt(arr: any[]) {
        let arrNew: any = []
        arr && arr.map((val) => {
            arrNew.push({ label: `${val.validityPeriod} ${TIMES.find(item => item.type === val.timeUnit)?.name}`, value: val.id })
        });

        return arrNew;
    }

    async upgradeNow() {
        upgradePackageStore.params.validityPeriod = 1;

        await Promise.all([
            upgradePackageStore.getFarmPackage(),
            upgradePackageStore?.getFarmPackageBuyInfo(),
            upgradePackageStore.getQrCodeProduct(),
            upgradePackageStore.getQrCodeBatches(),
        ])

        if (this.listFarmPackage?.length > 0) {
            this.listFarmPackage = this.listFarmPackage?.map((item: IPackage) => {
                item["isUsing"] = this.farmPackageBuyInfo.packageFarm?.packageData?.id === item?.id;
                if (item.packagePrices?.length > 0) {
                    item.packagePrices[0].isSelect = true;
                }

                return item;
            })
        }
    }

    async getDetailPackage(id: string | number | undefined, selectTypePackage?: IPackage) {
        const result = await upgradePackageService.getDetailPackage(id)
        if (result.status === HttpStatusCode.OK) {
            await upgradePackageStore.payNow(result.body || {}, selectTypePackage)
        }
    }

    async checkStatusPackageOrder(id: string | number | undefined) {
        const result = await upgradePackageService.getDetailPackageOrder(id)
        if (result.status === HttpStatusCode.OK) {
            this.statusPackageFarm = result.body?.status;
        } else toastUtil.error(result.body.message)
    }

    async payNow(item: any, _selectTypePackage?: IPackage) {
        let selectTypePackage = _selectTypePackage ? _selectTypePackage : item?.packagePrices?.[0]
        let itemPackage = {
            ...item,
            quantity: 1,
            price: selectTypePackage?.price,
            typePackage: selectTypePackage?.id,
            exportQRProduct: item?.exportQRProduct,
            packageId: item?.id,
            package: item,
            isFarmPackage: true,
        }

        let giftQRCodeExportBatch = {
            ...item?.giftQRCodeExportBatch,
            giftQRCode: "giftQRCode",
            quantity: 1,
            packageId: item?.giftQRCodeExportBatch?.id,
        }

        let giftQRCodeExportProduct = {
            ...item?.giftQRCodeExportProduct,
            giftQRCode: "giftQRCode",
            quantity: 1,
            packageId: item?.giftQRCodeExportProduct?.id,
        }

        let requestListData = [];
        requestListData.push(itemPackage)
        item?.giftQRCodeExportBatch?.qrCodeLimit > 0 && requestListData.push(giftQRCodeExportBatch)
        item?.giftQRCodeExportProduct?.qrCodeLimit > 0 && requestListData.push(giftQRCodeExportProduct)
        upgradePackageStore.requestListData = requestListData
    }

    checkValidate() {
        let checkError = false;

        if (!(upgradePackageStore.requestListData?.length > 0)) return true;

        upgradePackageStore.requestListData.forEach((item: any) => {
            let error = {
                packageId: ""
            }

            if (!item?.packageId) {
                checkError = true;
                error.packageId = "Không được để trống!";
            }

            item.error = error;
        });

        return checkError
    }

    async paySubmit(navigate: any) {
        if (upgradePackageStore.checkValidate()) {
            toastUtil.warning("Vui lòng nhập đầy đủ thông tin")
            return;
        }

        let packagePrice = upgradePackageStore.requestListData?.find((item: any) => item?.isFarmPackage)
        let packageQRCode = upgradePackageStore.requestListData
            .filter((itemData: any) => !itemData?.isFarmPackage)
            .map((item: any) => {
                return {
                    packageQRCodeId: item?.packageId,
                    quantity: item?.quantity
                }
            })

        let data = {
            packagePriceId: packagePrice?.typePackage || null,
            packageQRCode: packageQRCode,
        }

        upgradePackageStore.isLoadingBt = true
        const result = await upgradePackageService.add(data)
        upgradePackageStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            navigate(`/bank-information/${result.body?.id}`, {
                state: { infoBank: result.body }
            })
        } else toastUtil.warning(result.body.message, 1)
    }

    async comfirmPayment(id: string | number | undefined, isPaymentConfirmed: boolean) {
        upgradePackageStore.isLoadingBt = true
        const result = await upgradePackageService.comfirmPayment(id, isPaymentConfirmed)
        upgradePackageStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            $("#open_sent-success-modal").trigger("click")

            if (!isPaymentConfirmed) {
                toastUtil.success(`Hủy yêu cầu mua gói thành công`)
                historyOrderFarmStore.listHistoryOrder.map((item: any) => {
                    if (item.id == id) {
                        item.status = !isPaymentConfirmed ? "CANCEL" : "DRAFT" 
                        item.approveAt = new Date()
                    }
                })
                $('.button.btn-cancel').trigger('click')
            }
        } else toastUtil.warning(result.body.message, 1)
    }
}

export const upgradePackageStore = new UpgradePackageStore()