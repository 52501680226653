import React from 'react';
import "./FilterStyle.scss"
import { checkPermission } from "../../utils/Utils";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { DatePicker } from 'antd';
import dayjs from "dayjs";
import { dateUtils } from '../../utils/DateUtils';

export interface IFilter {
    label: string,
    type: string,
    value: string,
    callback: any,
    width?: any,
    onChange?: any,
    placeholder?: any,
    listValue?: any[],
    isShowClear?: boolean;
    format?: any;
    dateFrom?: any;
    dateTo?: any;
    className?: string;
}

interface IProps {
    label?: string
    buttonAdd?: any
    componentIcon?: any
    permissionCode?: any,
    isAdmin?: boolean,
    data: IFilter[]
}

export enum IType {
    date = 'date',
    select = "select",
    search = 'search',
    date_range = "date_range",
}

const { RangePicker } = DatePicker;

const FilterComponent = (item: IProps) => {

    const { t } = useTranslation();

    const onKeyDown = async (e: any, item: any) => {
        if (e.key === 'Enter') {
            await item.callback()
        }
    }


    const switchCase = (item: IFilter) => {
        switch (item.type) {
            case IType.date:
                return <div className={`${item?.className || ""} search_date`}>
                    <input type="date" value={item.value} onChange={(e: any) => { item.callback(e.currentTarget.value); item.value = e.currentTarget.value }} placeholder={item.placeholder ?? ''} />
                </div>
            case IType.select:
                return <div className={`${item?.className || ""} filter_select`}>
                    <select onChange={(e: any) => { item.callback(e.currentTarget.value); item.value = e.currentTarget.value }} value={item.value}>
                        <option value="">{t('choose')}</option>
                        {item.listValue && item.listValue.map((item, i) => {
                            return <option value={item.id} key={i}>{item.name}</option>
                        })}
                    </select>
                </div>
            case IType.search:
                return <div className={`${item?.className || ""} filter_search position-relative`} style={{ width: `${item.width ?? '189px'}` }}>
                    <input type="text" onChange={(e: any) => { item.onChange(e.currentTarget.value); item.value = e.currentTarget.value }} onKeyDown={(e: any) => onKeyDown(e, item)} placeholder={item.placeholder ?? ''} />
                    <i className="far fa-search color-green position-absolute" onClick={() => item.callback()} />
                </div>
            case IType.date_range:
                return (
                    <div  className={`${item?.className || ""} search_date`}>
                        <RangePicker presets={dateUtils.rangePresets} placeholder={item.placeholder ?? ['Từ ngày', 'Đến ngày']} style={{ width: 260 }}
                            onChange={(e: any) => item.callback(e)} allowClear={item.isShowClear}
                            format={item.format ?? 'DD/MM/YYYY'}
                            value={[
                                item.dateFrom === "" ? null : dayjs(item.dateFrom),
                                item.dateTo === "" ? null : dayjs(item.dateTo)
                            ] as any}
                        />
                    </div>
                );
        }
    }

    return (
        <div className="d-flex align-items-center justify-content-between filter_header">
            {/*{item.label && <h3 className="m-0">{item.label}</h3>}*/}
            <div className="d-flex align-items-center">
            {item.componentIcon &&
                <div className='component_filter'>
                    {item.componentIcon}
                    {/* {item.componentIcon
                        ? item.componentIcon
                        : <img src="/assets/ico/filter.svg" alt="" />
                    } */}
                </div>}
                {item.data && item.data.map((item, i) => {
                    return <div className="d-flex form_search align-items-center" key={i}>
                        <span className='flex-shrink-0'>{item.label}:</span>
                        {switchCase(item)}
                    </div>
                })}
            </div>
            {(item.isAdmin || (item.permissionCode && checkPermission(item.permissionCode))) && item.buttonAdd}
        </div>
    );
}

export default observer(FilterComponent);