import React from 'react';
import { observer } from "mobx-react";
import QRCode from "qrcode.react";
import { toastUtil } from "../../../../common/utils/ToastUtil";
import { number_format, urlImage } from "../../../../common/utils/Utils";
import { trackingDetailStore } from "../TrackingDetailStore";
import { numberUtil } from "../../../../common/utils/NumberUtil";
import { dateUtils } from "../../../../common/utils/DateUtils";
import { planStore } from "../../../planning/PlanStore";
import { Radio } from 'antd';
import { TYPE_QR_PACKAGE } from '../../../upgradePackage/UpgradePackageConstant';
import "../TrackingBatchStyle.scss"
import { upgradePackageStore } from '../../../upgradePackage/UpgradePackageStore';


const QRCodeTracking = () => {

    const copyLink = () => {
        var copyText: any = document.getElementById(`path_link`);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        toastUtil.success('Bạn đã sao chép thành công!')
    }

    let { exportQRProduct, exportQrBatchUnlimited, exportQrBatch } = trackingDetailStore.dataRequest?.packageData || {}

    const handleChangeQrExport = (e: any) => {
        let check = false;

        if (trackingDetailStore.typeQR === TYPE_QR_PACKAGE.PACKAGE_EXPORT_PRODUCT) {
            check = Number(trackingDetailStore.dataRequest?.packageQRCodeUsed?.remainingExportProduct) - Number(e.target.value) >= 0
        } else if (!trackingDetailStore.dataRequest.packageData?.exportQrBatchUnlimited) {
            check = Number(trackingDetailStore.dataRequest?.packageQRCodeUsed?.remainingExportBatch) - Number(e.target.value) >= 0
        } else {
            check = true
        }

        if (!check) {
            toastUtil.warning("Số lượng xuất QR không được vượt quá số lượng có");
            return;
        }

        trackingDetailStore.qty = numberUtil.regexNumber(e)
    }

    console.log("trackingDetailStore.qty && Number(trackingDetailStore.qty) !== 0", trackingDetailStore.qty && Number(trackingDetailStore.qty) !== 0)
    return (
        <div className="modal fade" id="qr_code_tracking" role="dialog" aria-hidden="true">
            <div className="modal-dialog" style={{ maxWidth: trackingDetailStore.step == 1 ? 700 : 880 }} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{trackingDetailStore.step == 1 ? 'Tạo link tải mã QR' : 'Mã QR mới'}</h5>
                        <button type="button" className="close" id="close_qr_code" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body d-flex justify-content-center pt-2 mb-3">
                        <div className="form d-flex justify-content-center">
                            {trackingDetailStore.step == 1 && <div className="step_1">
                                <div className="d-flex align-items-center">
                                    <div className='text-center position-relative bg-white' style={{ padding: 25, marginRight: 20 }}>
                                        <QRCode id="download_qrCode" level="L" value={trackingDetailStore.pageHelper} size={190} />
                                        {trackingDetailStore.dataRequest.logo && <div
                                            className="logo_farm position-absolute d-flex align-items-center justify-content-center w-100"
                                            style={{ left: 0, top: '100px' }}>
                                            <img width={36} src={urlImage(trackingDetailStore.dataRequest.logo)} alt="" />
                                        </div>}
                                        <p className="mt-1 mb-0"
                                            style={{ fontSize: 12 }}>{trackingDetailStore.dataRequest.batchCode}</p>
                                    </div>

                                    <div style={{ width: 320 }}>
                                        <div>
                                            <label className='w-100 fs-12px color-181818'>QR xuất <span className="text-danger">*</span></label>
                                            <div className="d-flex gap-5">
                                                {
                                                    exportQRProduct &&
                                                    <div className="w-50 radio">
                                                        <Radio
                                                            className='fs-12px'
                                                            checked={trackingDetailStore.typeQR === TYPE_QR_PACKAGE.PACKAGE_EXPORT_PRODUCT}
                                                            onChange={() => trackingDetailStore.typeQR = TYPE_QR_PACKAGE.PACKAGE_EXPORT_PRODUCT}>
                                                            Theo sản phẩm
                                                        </Radio>
                                                    </div>
                                                }
                                                {
                                                    (exportQrBatchUnlimited || exportQrBatch) &&
                                                    <div className="w-50 radio">
                                                        <Radio
                                                            className='fs-12px'
                                                            checked={trackingDetailStore.typeQR === TYPE_QR_PACKAGE.PACKAGE_EXPORT_BATCH}
                                                            onChange={() => trackingDetailStore.typeQR = TYPE_QR_PACKAGE.PACKAGE_EXPORT_BATCH}>
                                                            Theo lô
                                                        </Radio>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <p className="text-center my-2" style={{ fontSize: 14 }}>
                                            {
                                                trackingDetailStore.typeQR === TYPE_QR_PACKAGE.PACKAGE_EXPORT_PRODUCT
                                                    ? <>
                                                        Bạn đang có
                                                        <span className="color-green font-weight-medium px-3px">{number_format(trackingDetailStore.dataRequest?.packageQRCodeUsed?.remainingExportProduct) || 0}</span>
                                                        mã theo sản phẩm được tạo!
                                                    </> : !trackingDetailStore.dataRequest.packageData?.exportQrBatchUnlimited && <>
                                                        Bạn đang có
                                                        <span className="color-green font-weight-medium px-3px">{number_format(trackingDetailStore.dataRequest?.packageQRCodeUsed?.remainingExportBatch) || 0}</span>
                                                        mã theo lô được tạo!
                                                    </>
                                            }
                                        </p>
                                        <div className="mb-2">
                                            <label style={{ fontSize: 12, color: '#181818' }}>Chọn thửa đất <span className="text-danger">*</span></label>
                                            <select
                                                className={`w-100 ${!trackingDetailStore.landId && trackingDetailStore.errors.landId ? 'border_error' : ''}`}
                                                style={{ height: 40, border: `1px solid #181818`, fontSize: 12, padding: `0 12px` }}
                                                value={trackingDetailStore.landId}
                                                onChange={(e: any) => trackingDetailStore.landId = e.currentTarget.value}
                                            >
                                                <option value="">Chọn thửa</option>
                                                {planStore.listLandPlan && planStore.listLandPlan.map((item, i) => {
                                                    return <option value={item.id} key={i}>{item.name}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div className="mb-2">
                                            <label className='fs-12px color-181818'>
                                                Nhập số lượng QR tải về
                                                <span className="text-danger"> *</span></label>
                                            <input type="text"
                                                value={trackingDetailStore.qty && Number(trackingDetailStore.qty) !== 0 ? number_format(trackingDetailStore.qty) : ''}
                                                onChange={handleChangeQrExport}
                                                className={`w-100 ${((!trackingDetailStore.qty && trackingDetailStore.errors.qty) || trackingDetailStore.qty > 10000) ? 'border_error' : ''}`}
                                                placeholder="Nhập số lượng QR tải về"
                                                style={{ height: 40, border: `1px solid #181818`, fontSize: 12, padding: `0 12px` }}
                                            />
                                        </div>
                                        <div className="d-flex mt-4 justify-content-center">
                                            <button className="btn send-request w-100" disabled={trackingDetailStore.isLoadingBt}
                                                onClick={() => trackingDetailStore.addLinkDownloadQr()}
                                                style={{ height: 40, fontSize: 14 }}>{trackingDetailStore.isLoadingBt ? <i className="fa fa-spinner text-white fa-spin" /> : 'Tạo link tải mã'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                            {trackingDetailStore.step === 2 && <div className="step_2">
                                {
                                    trackingDetailStore.typeQR === TYPE_QR_PACKAGE.PACKAGE_EXPORT_PRODUCT
                                        ? <>
                                            Bạn đang có
                                            <span className="color-green font-weight-medium px-3px">{number_format(trackingDetailStore.dataRequest?.packageQRCodeUsed?.remainingExportProduct) || 0}</span>
                                            mã theo sản phẩm được tạo!
                                        </> : !trackingDetailStore.dataRequest.packageData?.exportQrBatchUnlimited && <>
                                            Bạn đang có
                                            <span className="color-green font-weight-medium px-3px">{number_format(trackingDetailStore.dataRequest?.packageQRCodeUsed?.remainingExportBatch) || 0}</span>
                                            mã theo lô được tạo!
                                        </>
                                }
                                <div className="link_download_qr">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span
                                            style={{ fontSize: 12 }}>{dateUtils.formatDate(trackingDetailStore.dataRequest.createdAt, 'DD/MM/YYYY HH:mm')}</span>
                                        <div className="color-green"><span
                                            className="font-weight-medium pr-3px">{number_format(trackingDetailStore.qty)}</span>
                                            Mã QR
                                            {
                                                trackingDetailStore.typeQR === TYPE_QR_PACKAGE.PACKAGE_EXPORT_PRODUCT ? " theo sản phẩm " : " theo lô "
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex action_ mt-2 align-items-center justify-content-center">
                                        <input type="text" id="path_link" onChange={() => { }}
                                            value={`${process.env.REACT_APP_API_DOMAIN}/farm/v1/tracking_batch/${trackingDetailStore.dataRequest.id}${trackingDetailStore.dataRequest.qrFile}`} />
                                        <button title="Sao chép" onClick={() => copyLink()}>
                                            <img src="/assets/ico/ico_copy.png" alt="" />
                                        </button>
                                        <button title="Tải mã" disabled={trackingDetailStore.isLoadingBt} onClick={async () => await trackingDetailStore.downloadQR(trackingDetailStore.dataRequest.id, trackingDetailStore.dataRequest.qrFile)}>
                                            <img src="/assets/ico/ico_download.png" alt="" />
                                        </button>
                                        <button title={'In mã qr'} onClick={() => {
                                            trackingDetailStore.errorBackgroundPrint = ''
                                            trackingDetailStore.backgroundPrint = 1
                                        }} data-dismiss="modal" data-toggle="modal" data-target="#qr_print_code">
                                            <img src="/assets/ico/ico_printer.png" alt="" />
                                        </button>
                                    </div>
                                </div>

                                <span className="mt-2 mb-4 font-italic" style={{
                                    color: 'red',
                                    fontSize: 14
                                }}>Chú ý*: Đường link tải về tồn tại trong 24h</span>
                                <div className="d-flex mt-3 justify-content-end">
                                    <button className="btn send-request-outline mr-4" style={{ width: 189, height: 42 }}
                                        onClick={() => trackingDetailStore.clearDownloadQR()}>Tạo mã mới
                                    </button>
                                    <button className="btn send-request"
                                        onClick={async () => {
                                            trackingDetailStore.tabActive = trackingDetailStore.typeQR
                                            await trackingDetailStore.getHistoryQr(trackingDetailStore.dataRequest.id)
                                        }}
                                        data-dismiss="modal" data-toggle="modal" data-target="#history_qr"
                                        style={{ width: 189, height: 42 }}>Lịch sử
                                    </button>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default observer(QRCodeTracking);