import React from 'react'
import { observer } from 'mobx-react-lite'
import { packageStore, TypeTime } from "../PackageStore";
import { Checkbox, Radio, Switch } from 'antd';
import { numberUtil } from "../../../../common/utils/NumberUtil";
import { number_format } from "../../../../common/utils/Utils";
import InputAnt from "../../../../common/component/form/InputAnt";
import NoContentTable from "../../../../common/component/NoContentTable";
import "../PackagePage.scss"


const AddEditPackage = () => {

    const addPackagePrice = () => {
        packageStore.dataRequest.packagePrices.push({
            price: '',
            validityPeriod: '',
            errorPrice: "",
            errorValidityPeriod: "",
        })
    }

    return (
        <div className='modal fade' id='add_edit_package' role="dialog" aria-hidden="true" data-backdrop="static">
            <div className={`${packageStore.dataRequest.type === TypeTime.PAID ? "max-w-1200px" : "max-w-700px"} modal-dialog my-16px`} role="document">
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h5 className="modal-title color-005CF7">Thiết lập gói {packageStore.dataRequest.type === TypeTime.PAID ? 'bán' : 'dùng thử'}</h5>
                        <button
                            type="button"
                            className="close"
                            id="close_package"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => packageStore?.isCloseAddPackages ? packageStore.getPackage() : {}}
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className='modal-body py-16px px-lg-10 d-flex gap-16px'>
                        <div className='flex-1px d-flex flex-column gap-10px'>
                            {!packageStore.dataRequest.id && <div className="d-flex gap-5">
                                <div className="w-50">
                                    <Radio checked={packageStore.dataRequest.type === TypeTime.PAID}
                                        onChange={() => {
                                            packageStore.dataRequest.type = TypeTime.PAID
                                            packageStore.dataRequest.packagePrices = [
                                                {
                                                    price: '',
                                                    validityPeriod: 1,
                                                    errorPrice: "",
                                                    errorValidityPeriod: "",
                                                }
                                            ]
                                        }}>
                                        Gói trả phí
                                    </Radio>
                                </div>
                                <div className="w-50">
                                    <Radio checked={packageStore.dataRequest.type === TypeTime.TRIAL}
                                        onChange={() => {
                                            packageStore.dataRequest.type = TypeTime.TRIAL
                                            packageStore.dataRequest.isRecommended = false
                                            packageStore.dataRequest.packagePrices = [
                                                {
                                                    validityPeriod: 7,
                                                    errorValidityPeriod: "",
                                                }
                                            ]
                                        }}>
                                        Gói dùng thử
                                    </Radio>
                                </div>
                            </div>}
                            <div>
                                <label className='form-label mb-4px light-height-22px'>Tên gói <span
                                    className="text-danger">*</span></label>
                                <InputAnt value={packageStore.dataRequest.name}
                                    isError={packageStore.errors.name} showCount
                                    onChange={(e: any) => packageStore.dataRequest.name = e.currentTarget.value}
                                    placeholder='Nhập tên gói' />
                            </div>
                            <div>
                                <div className="d-flex mb-4px align-items-center justify-content-between">
                                    <label className='form-label mb-0 light-height-22px'>Số lượng người dùng <span
                                        className="text-danger">*</span></label>
                                    <label className="d-flex align-items-center gap-8px mb-0">
                                        <span className="fs-14px fw-400 me-2">Không giới hạn người dùng</span>
                                        <Checkbox checked={packageStore.dataRequest.unlimitedUser} className="mb-0"
                                            onChange={(e: any) => packageStore.dataRequest.unlimitedUser = e.target.checked} />
                                    </label>

                                </div>
                                <InputAnt
                                    value={packageStore.dataRequest.unlimitedUser ? 'Không giới hạn' : packageStore.dataRequest.limitUser ? number_format(packageStore.dataRequest.limitUser) : ''}
                                    disabled={packageStore.dataRequest.unlimitedUser} maxLength={15}
                                    isError={packageStore.errors.limitUser} className="text-end"
                                    onChange={(e: any) => packageStore.dataRequest.limitUser = numberUtil.regexNumber(e)}
                                    placeholder='Nhập số lượng người dùng' />
                            </div>
                            <div className="d-flex align-items-center flex-wrap gap-10px justify-content-between">
                                <Checkbox
                                    className='w-100'
                                    checked={packageStore.dataRequest.exportQrBatch}
                                    onChange={(e: any) => {
                                        packageStore.dataRequest.giftQRBatchQuantity = "";
                                        packageStore.dataRequest.exportQrBatchUnlimited = false;
                                        packageStore.dataRequest.exportQrBatch = e.target.checked
                                    }}
                                >
                                    <span className="fs-14px fw-400 me-2">Xuất mã QR Theo lô</span>
                                </Checkbox>
                                <Checkbox
                                    className='w-100'
                                    checked={packageStore.dataRequest.exportQRProduct}
                                    onChange={(e: any) => {
                                        packageStore.dataRequest.giftQRProductQuantity = "";
                                        packageStore.dataRequest.exportQRProduct = e.target.checked
                                    }}
                                >
                                    <span className="fs-14px fw-400 me-2">Xuất mã QR Theo sản phẩm</span>
                                </Checkbox>
                                <Checkbox
                                    className='w-100'
                                    checked={packageStore.dataRequest.exportExcel}
                                    onChange={(e: any) => packageStore.dataRequest.exportExcel = e.target.checked}
                                >
                                    <span className="fs-14px fw-400 me-2">Xuất Excel thông tin dữ liệu</span>
                                </Checkbox>
                                <Checkbox
                                    className='w-100'
                                    checked={packageStore.dataRequest.fraudAlert}
                                    onChange={(e: any) => packageStore.dataRequest.fraudAlert = e.target.checked}
                                >
                                    <span className="fs-14px fw-400 me-2">Cảnh báo gian lận</span>
                                </Checkbox>
                            </div>

                            <div>
                                <div className="d-flex mb-4px align-items-center justify-content-between">
                                    <label className='form-label mb-0 light-height-22px'>Số QR theo lô tặng kèm</label>
                                    <label className="d-flex align-items-center gap-8px mb-0">
                                        <span className="fs-14px fw-400 me-2">Không giới hạn</span>
                                        <Checkbox disabled={!packageStore.dataRequest.exportQrBatch} checked={packageStore.dataRequest.exportQrBatchUnlimited} className="mb-0"
                                            onChange={(e: any) => {
                                                packageStore.dataRequest.exportQrBatchUnlimited = e.target.checked;
                                                packageStore.dataRequest.giftQRBatchQuantity = 0;
                                            }} />
                                    </label>
                                </div>
                                <InputAnt
                                    value={packageStore.dataRequest.exportQrBatchUnlimited ? 'Không giới hạn' : packageStore.dataRequest.giftQRBatchQuantity ? number_format(packageStore.dataRequest.giftQRBatchQuantity) : ""}
                                    disabled={packageStore.dataRequest.exportQrBatchUnlimited || !packageStore.dataRequest.exportQrBatch} maxLength={15}
                                    isError={packageStore.errors.giftQRBatchQuantity} className="text-end"
                                    onChange={(e: any) => packageStore.dataRequest.giftQRBatchQuantity = numberUtil.regexNumber(e)}
                                    placeholder={packageStore.dataRequest.exportQrBatchUnlimited ? 'Không giới hạn' : 'Nhập số QR theo lô tặng kèm'} />
                            </div>
                            <div>
                                <label className='form-label mb-4px light-height-22px'>Số QR theo sản phẩm tặng kèm</label>
                                <InputAnt
                                    value={packageStore.dataRequest.giftQRProductQuantity ? number_format(packageStore.dataRequest.giftQRProductQuantity) : ""}
                                    className="text-end" maxLength={15}
                                    isError={packageStore.errors.giftQRProductQuantity}
                                    disabled={!packageStore.dataRequest.exportQRProduct}
                                    onChange={(e: any) => packageStore.dataRequest.giftQRProductQuantity = numberUtil.regexNumber(e)}
                                    placeholder='Nhập số QR theo sản phẩm tặng kèm' />
                            </div>

                            {packageStore.dataRequest.type === TypeTime.PAID ? <>
                                <div className="d-flex justify-content-between gap-5 py-6px">
                                    <div className="d-flex w-50 align-items-center gap-8px">
                                        <div className="fs-14px fw-500">Trạng thái</div>
                                        <Switch checked={packageStore.dataRequest.isActive}
                                            onChange={() => packageStore.dataRequest.isActive = !packageStore.dataRequest.isActive} />
                                    </div>
                                    <div className="d-flex align-items-center w-50 gap-8px mb-0">
                                        <Checkbox
                                            checked={packageStore.dataRequest.isRecommended}
                                            onChange={(e: any) => packageStore.dataRequest.isRecommended = e.target.checked}
                                        >
                                            <span className="fs-14px fw-400 me-2">Gói khuyên dùng</span>
                                        </Checkbox>
                                    </div>
                                </div>
                            </> : <div>
                                <label className='form-label mb-2 light-height-22px'>Thời hạn dùng thử <span
                                    className="text-danger">*</span></label>
                                <InputAnt value={Number(packageStore.dataRequest.packagePrices[0].validityPeriod) || ""} suffix="Ngày"
                                    isError={packageStore.dataRequest.packagePrices[0].errorValidityPeriod} maxLength={3}
                                    onChange={(e: any) => packageStore.dataRequest.packagePrices[0].validityPeriod = numberUtil.regexNumber(e)}
                                    placeholder='Nhập thời hạn dùng thử' />
                            </div>}
                        </div>

                        {packageStore.dataRequest.type === TypeTime.PAID &&
                            <div className='flex-1px'>
                                <div className="d-flex mb-2 align-items-center justify-content-between">
                                    <h4 className="fs-16px fw-500 color-00953D">Giá gói</h4>
                                    <button
                                        className='btn btn-sm fs-14px color-00953D bg-ECF5FF border-00953D hover-color-00953D'
                                        onClick={() => addPackagePrice()}>+ Thêm
                                    </button>
                                </div>

                                <div className="table-responsive p-0 border overflow-auto calc-max-h-vh-300px">
                                    <table className="table align-middle table-sticky-header table-hover gy-7 gs-7 mb-0">
                                        <thead>
                                            <tr className="bg-F2F3F5">
                                                <th className="text-center py-13px">STT</th>
                                                <th className="text-center py-13px">Năm</th>
                                                <th className="text-end py-13px">Giá bán</th>
                                                <th className="text-center py-13px" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {packageStore.dataRequest.packagePrices && packageStore.dataRequest.packagePrices.length > 0 ? (
                                                packageStore.dataRequest.packagePrices.map((item: any, i: any) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td width="10% vertical-align-top" className="text-center">{i + 1}</td>
                                                            <td width="160 vertical-align-top">
                                                                <InputAnt className="text-center" maxLength={2}
                                                                    isError={item.errorValidityPeriod}
                                                                    value={Number(item.validityPeriod) || ""} disabled={i === 0}
                                                                    onChange={(e: any) => item.validityPeriod = numberUtil.regexNumber(e)}
                                                                    placeholder='Nhập năm' />
                                                            </td>
                                                            <td className='vertical-align-top'>
                                                                <InputAnt className="text-end" maxLength={18}
                                                                    value={item.price && Number(item.price) !== 0 ? number_format(item.price) : ''}
                                                                    isError={item.errorPrice}
                                                                    onChange={(e: any) => item.price = numberUtil.regexNumber(e)}
                                                                    placeholder='Nhập giá bán' />
                                                            </td>
                                                            <td width="10% vertical-align-top" className="text-center ps-0">
                                                                {packageStore.dataRequest.packagePrices && packageStore.dataRequest.packagePrices.length > 1 && i > 0 &&
                                                                    <i onClick={() => packageStore.dataRequest.packagePrices.splice(i, 1)}
                                                                        className="fas fs-16px fa-trash text-danger" />}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                                )
                                            ) : (
                                                <NoContentTable height={120} colSpan={4} />
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                    </div>

                    <div className="modal-footer d-flex justify-content-between">
                        <Checkbox checked={packageStore.dataRequest.isAddPackages} className="mb-0"
                            onChange={(e: any) => packageStore.dataRequest.isAddPackages = e.target.checked} >
                            <span className="fs-14px fw-400 me-2">Tạo thêm gói</span>
                        </Checkbox>
                        <button type="button" className="btn rounded-4px send-request"
                            onClick={() => packageStore.addEdit()}
                            disabled={packageStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default observer(AddEditPackage)