import { observable } from 'mobx'
import $ from 'jquery'
import { toastUtil } from "../../../common/utils/ToastUtil";
import HttpStatusCode from "../../../common/constants/HttpErrorCode";
import { qrCodePackageService } from "./QrCodePackageService";
import { getIDParameter } from '../../../common/utils/Utils';

interface IError {
    name: string,
    price: string,
    qrCodeLimit: string,
}
interface IDataRequest {
    id: string | number,
    name: string,
    type: string,
    price: string | number,
    qrCodeLimit: string | number
    isActive: boolean,
    isAddPackages?: boolean,
}

export enum TypeQrCode {
    PACKAGE_EXPORT_BATCH = "PACKAGE_EXPORT_BATCH",
    PACKAGE_EXPORT_PRODUCT = "PACKAGE_EXPORT_PRODUCT "
}

class QrCodePackageStore {
    @observable id: number | string = ''
    @observable page: number = 0
    @observable name: string = ''
    @observable type: string = qrCodePackageStore.checkType(getIDParameter(3))
    @observable totalPage: number = 0
    @observable listPackage: any[] = []
    @observable errors: IError = {
        name: '',
        price: '',
        qrCodeLimit: '',
    }
    @observable dataRequest: IDataRequest = {
        id: '',
        name: '',
        price: '',
        qrCodeLimit: '',
        type: TypeQrCode.PACKAGE_EXPORT_BATCH,
        isActive: true,
    }
    @observable isOpenDetail: boolean = false
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable isCloseAddPackages: boolean = false


    checkType(type: string) {
        switch (type) {
            case "qrcode-batch": return TypeQrCode.PACKAGE_EXPORT_BATCH
            case "qrcode-product": return TypeQrCode.PACKAGE_EXPORT_PRODUCT
            default: return TypeQrCode.PACKAGE_EXPORT_BATCH
        }
    }

    clearError() {
        this.errors = {
            name: '',
            price: '',
            qrCodeLimit: '',
        }
    }

    clearFilter() {
        this.name = ""
        this.type = ""
    }

    clearForm() {
        this.clearError()
        this.dataRequest = {
            id: '',
            name: '',
            price: '',
            type: qrCodePackageStore.checkType(getIDParameter(3)),
            qrCodeLimit: '',
            isActive: true,
        }
        this.isCloseAddPackages = false;
    }

    async getQrCodePackage() {
        this.isLoading = true;
        const result = await qrCodePackageService.getQrCodePackage()
        this.isLoading = false;
        if (result.status === 200) {
            this.listPackage = result.body.data;
            this.totalPage = result.body.metadata.totalPage
        }
    }

    async getDetail(id: any) {
        this.clearError()
        const result = await qrCodePackageService.getDetail(id);
        if (result.status === 200) {
            this.dataRequest.isAddPackages = false;
            this.dataRequest = result.body;
        }
    }

    async changeState(id: number, isActive: boolean) {
        this.isLoadingBt = true
        const result = await qrCodePackageService.changeState(id, !isActive)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${isActive ? 'Ngưng' : 'Bật'} hoạt động thiết lập gói QR Code thành công`)
            this.listPackage.map((item: any) => {
                if (item.id === id) {
                    item.isActive = !isActive
                }
            })
        } else toastUtil.error(result.body.message)
    }


    async addEdit() {
        this.clearError()

        let { id, name, isActive, price, type, qrCodeLimit } = this.dataRequest
        console.log(price)
        console.log(qrCodeLimit)

        let error = false
        if (!name?.trim()) {
            error = true
            this.errors.name = 'Tên gói không được để trống!'
        }
        if (!Number(price)) {
            error = true
            this.errors.price = 'Giá bán không được để trống!'
        }

        if (!Number(qrCodeLimit)) {
            error = true
            this.errors.qrCodeLimit = 'Số lượng mã không được để trống!'
        }

        if (error) {
            return
        }

        let data = {
            name: name,
            isActive: isActive,
            price: price,
            type: type,
            qrCodeLimit: qrCodeLimit,
            usageType: "SALE"
        }

        this.isLoadingBt = true
        const result = id ? await qrCodePackageService.edit(id, data) : await qrCodePackageService.add(data)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${id ? "Cập nhật" : 'Thiết lập'} thiết lập gói QR Code thành công`, 2)
            if (this.dataRequest.isAddPackages) {
                this.clearForm()
                this.isCloseAddPackages = true;
                this.dataRequest.isAddPackages = true;
            } else {
                this.clearForm()
                $('#close_qr_package').trigger('click')
                this.getQrCodePackage().then()
            }
            // $('#close_qr_package').trigger('click')
            // this.clearForm()
            // this.getQrCodePackage().then()
        } else {
            toastUtil.error(result.body.message)
        }
    }


    async delete() {
        this.isLoadingBt = true
        const result = await qrCodePackageService.delete(this.id)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Xóa thiết lập gói QR Code thành công`, 1)
            $('#close_delete').trigger('click')
            this.getQrCodePackage().then()
        } else toastUtil.error(result.body.message)

    }


}

export const qrCodePackageStore = new QrCodePackageStore();


