import React, { useEffect } from 'react'
import { checkExpirationTime, convertToLocaleVN } from '../../../common/utils/Utils'
import Paragraph from 'antd/es/typography/Paragraph';
import "../UpgradePackage.scss"
import { useLocation, useNavigate } from 'react-router-dom';
import { upgradePackageStore } from '../UpgradePackageStore';
import SentSuccessfullyModal from './SentSuccessfullyModal';
import { useTranslation } from 'react-i18next';
import { STATUS_PACKAGE } from '../UpgradePackageConstant';
import { observer } from 'mobx-react';

function BankInformation() {
    const navigate = useNavigate()
    const location = useLocation();
    const { t } = useTranslation();

    let { infoBank } = location.state || {}

    const handleBack = async () => {
        navigate(`/history-order-farm`)
    }

    useEffect(() => {
        upgradePackageStore.checkStatusPackageOrder(infoBank?.id)
    }, [infoBank?.id])

    return (
        <div className={`${checkExpirationTime().isExpired ? "is-expired" : ""} bank-infomation bg-F3F4F5 h-100`}>
            <div className='w-1000px'>
                <div className="p-32px bg-white p-0 d-flex flex-column align-items-center">
                    <div className='d-flex w-100'>
                        <div className='flex-1px mr-32px gap-12px d-flex flex-column'>
                            <button className='button btn-cancel bg-F6F8FF hover-bg-F3F3F3 fs-16px min-w-120px w-160px' onClick={handleBack}>
                                <i className="fs-18px mt-3px far color-061020 mr-2 fa-arrow-left" />
                                {t("purchaseHistory")}
                            </button>
                            <div className='d-flex w-100 align-items-center justify-content-between'>
                                <span>{t("bankName")}</span>
                                <span className='fw-600'>{infoBank?.bankingInfo?.bankName}</span>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span>{t("accountHolder")}</span>
                                <span className='fw-600'>{infoBank?.bankingInfo?.bankAccountOwner}</span>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span>{t("accountNumber")}</span>
                                <Paragraph
                                    className='m-0 fw-600'
                                    copyable={{
                                        icon: [<img src="/assets/ico/document-copy.svg" alt='' width={20} className='' />],
                                        tooltips: ['click here', 'you clicked!!'],
                                    }}  >
                                    {infoBank?.bankingInfo?.bankSTK}
                                </Paragraph>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span>{t("price")}</span>
                                <span className='fw-600'>{convertToLocaleVN(infoBank?.bankingInfo?.totalPrice)}</span>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span>{t("paymentDescription")}</span>
                                <Paragraph
                                    className='m-0 fw-600'
                                    copyable={{
                                        icon: [<img src="/assets/ico/document-copy.svg" alt='' width={20} className='' />],
                                        tooltips: ['click here', 'you clicked!!'],
                                    }}  >
                                    {infoBank?.code}
                                </Paragraph>
                            </div>
                            <div className="dashed-line" />
                            <span className='color-00953D fw-500'>{t("notePay")} </span>
                            {
                                upgradePackageStore.statusPackageFarm === STATUS_PACKAGE.DRAFT &&
                                <button
                                    className="btn button_add text-white w-270px rounded-6px"
                                    onClick={() => upgradePackageStore.comfirmPayment(infoBank?.id, true)}
                                >
                                    {t("paymentConfirmation")}
                                </button>
                            }
                            <button
                                id="open_sent-success-modal"
                                type="button"
                                className="d-none"
                                data-toggle="modal"
                                data-target={`#sent-success-modal`} />
                        </div>
                        <div className='w-260px'>
                            <div className='frame-rq'>
                                <img src="/assets/images/frame_qr.png" alt="" className='w-100' />
                                <img src={infoBank?.imageBanking} className='calc-w-20px rq-bank-information' alt='' />
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
            <SentSuccessfullyModal />
        </div>
    )
}

export default observer(BankInformation)