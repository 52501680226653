import {
  deleteRequest,
  getRequest,
  IApiResponse, patchRequest,
  postRequest,
  putRequest
} from '../../../common/helpers/RequestHelper'
import {qrCodePackageStore} from "./QrCodePackageStore";


class QrCodePackageService {

  public getQrCodePackage(): Promise<IApiResponse>{
    return getRequest(`/v1/qr_code_packages?page=${qrCodePackageStore.page}&size=10
      ${qrCodePackageStore.name?.trim() ? `&name=${qrCodePackageStore.name?.trim()}` : ''}
      ${qrCodePackageStore.type ? `&type=${qrCodePackageStore.type}` : ''}
      `)
  }

  public getDetail(id: any): Promise<IApiResponse>{
    return getRequest(`/v1/qr_code_packages/${id}`)
  }

  public add(data: object): Promise<IApiResponse>{
    return postRequest(`/v1/qr_code_packages`, data)
  }

  public edit(id: any, data: object): Promise<IApiResponse>{
    return putRequest(`/v1/qr_code_packages/${id}`, data)
  }

  public changeState(id: any, isActive: boolean): Promise<IApiResponse>{
    return patchRequest(`/v1/qr_code_packages/${id}?isActive=${isActive}`, {})
  }

  public delete(id: any): Promise<IApiResponse>{
    return deleteRequest(`/v1/qr_code_packages/${id}`, {})
  }


}

export const qrCodePackageService = new QrCodePackageService()

