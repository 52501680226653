import React, { useEffect } from 'react'
import { historyOrderFarmStore } from './HistoryOrderFarmStore'
import Loading from '../../common/component/Loading'
import { useTranslation } from 'react-i18next';
import NoContent from '../../common/component/NoContent';
import ButtonAdd from '../../common/component/ButtonAdd';
import { observer } from 'mobx-react';
import { Tag } from 'antd';
import { checkExpirationTime, convertPrice, sttPage } from '../../common/utils/Utils';
import { upgradePackageStore } from '../upgradePackage/UpgradePackageStore';
import UpgradeFarmModal from '../upgradePackage/components/UpgradeFarmModal';
import ViewHistoryOrderFarm from './components/ViewHistoryOrderFarm';
import PopupCancelPackage from './components/PopupCancel';
import { dateUtils } from '../../common/utils/DateUtils';
import Paginate from '../../common/component/Paginate';
import { toastUtil } from '../../common/utils/ToastUtil';
import SentSuccessfullyModal from '../upgradePackage/components/SentSuccessfullyModal';

export const renderStatus = (t: any, state: string | number | null) => {
  switch (state) {
    case "IN_PROCESS":
      return <Tag bordered={false} className="fs-14 no-whitespace fw-700 text-center pe-2 ps-2 m-0 h-24px" color="#F5F3FF"
        style={{ width: 100, color: '#7B61FF', borderRadius: 12 }}>{t("processing")}</Tag>
    case "CANCEL":
    case "CANCELED":
      return <Tag bordered={false} className="fs-14 no-whitespace fw-700 text-center pe-2 ps-2 m-0" color="#F1FAFF"
        style={{ width: 100, color: '#15476D', borderRadius: 12 }}>{t("cancel")}</Tag>
    case "DRAFT":
      return <Tag bordered={false} className="fs-14 no-whitespace fw-700 text-center pe-2 ps-2 m-0" color="#F1FAFF"
        style={{ width: 100, color: '#15476D', borderRadius: 12 }}>{t("draft")}</Tag>
    case "APPROVED":
      return <Tag bordered={false} className="fs-14 no-whitespace fw-700 text-center pe-2 ps-2 m-0 h-24px" color="#efffef"
        style={{ width: 100, color: '#158212', borderRadius: 12 }}>{t("approved")}</Tag>
    case "REJECTED":
      return <Tag bordered={false} className="fs-14 no-whitespace fw-700 text-center pe-2 ps-2 m-0 h-24px" color="#FFEEEF"
        style={{ width: 100, color: '#E3000F', borderRadius: 12 }}>{t("refuse")}</Tag>
    case "PENDING":
      return <Tag bordered={false} className="fs-14 no-whitespace fw-700 text-center pe-2 ps-2 m-0 h-24px" color="#E6E6E6"
        style={{ width: 100, color: '#808089', borderRadius: 12 }}>{t("pendingApproval")}</Tag>
  }
}

function HistoryOrderFarmPage() {
  const { t } = useTranslation();
  let farmOwner = localStorage.getItem("farmOwner");

  useEffect(() => {
    historyOrderFarmStore.getHistoryOrderFarm();
    upgradePackageStore.requestListData = [];
  }, [])

  const handlePageClick = async (data: any) => {
    historyOrderFarmStore.page = data.selected
    await historyOrderFarmStore.getHistoryOrderFarm()
}

  return (
    <div className={`${checkExpirationTime().isExpired ? "is-expired" : ""} p-16px h-100`}>
      <div className='p-16px bg-white'>
        <div className="d-flex align-items-center mb-12px justify-content-between">
          <h5 className="m-0 color-00953D">{t('purchaseHistory')}</h5>
          <div>
            {
              (farmOwner) &&
              <ButtonAdd
                className={"rounded-4px px-12px"}
                text={t("buyMore")}
                dataTarget={"upgrade-farm-modal" } 
                icon={'/assets/ico/shopping-cart.svg'}
                onClick={() => {
                  upgradePackageStore.isOpenHistory = true;
                  upgradePackageStore.upgradeNow();
                }}
              />
            }
          </div>
        </div>
        {historyOrderFarmStore.isLoading ? <Loading /> :
          <div className='table-responsive table-history-order overflow-y calc-max-h-vh-270px'>
            <table className='table fw-500 align-middle table-sticky-header table-hover table-rounded border gy-7 gs-7 '>
              <thead>
                <tr>
                  <th className="text-center">STT</th>
                  <th>{t('orderCode')}</th>
                  <th className="text-center">{t('datePurchase')}</th>
                  <th className="text-end">{t('totalAmount')}</th>
                  <th className="text-center w-180px">{t('orderStatus')}</th>
                  <th className="text-center w-120px">{t('actions')}</th>
                </tr>
              </thead>
              <tbody>
                {historyOrderFarmStore.listHistoryOrder.length > 0 ? historyOrderFarmStore.listHistoryOrder.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-center">{sttPage(historyOrderFarmStore.page, i)}</td>
                      <td>{item?.code}</td>
                      <td className='text-center'>{dateUtils.formatDate(item.createdAt, 'DD/MM/YYYY')}</td>
                      <td className='text-end'>{convertPrice(item?.totalPrice)}</td>
                      <td className='text-center w-180px'>{renderStatus(t, item?.status || "REJECT")}</td>
                      <td className="text-center w-120px">
                        <div className="action justify-content-start">
                          <button
                            className="bt_action"
                            title={t('view')}
                            data-toggle="modal"
                            data-target='#view-history-order-farm'
                            onClick={() => historyOrderFarmStore.getDetail(item?.id)}
                          >
                            <img src="/assets/ico/action/ico_detail.svg" alt="" />
                          </button>
                          {
                            item.status === "DRAFT" &&
                            <button className="bt_action" title={"Hủy"}
                              data-toggle="modal" data-target="#popup_cancel"
                              onClick={() => historyOrderFarmStore.id = item.id}>
                              <img src="/assets/ico/action/ico_cancel.svg" alt="" />
                            </button>
                          }
                        </div>
                      </td>
                    </tr>
                  )
                }) : <tr>
                  <td colSpan={6}><NoContent /></td>
                </tr>}
              </tbody>
            </table>
          </div>
        }
        <div className="d-flex align-items-center justify-content-center mt-4">
          <Paginate changePage={handlePageClick} totalPage={historyOrderFarmStore.totalPage}
            currentPage={historyOrderFarmStore.page} />
        </div>

        <PopupCancelPackage />
        <UpgradeFarmModal />
        <ViewHistoryOrderFarm />
        <SentSuccessfullyModal />
      </div>
    </div>
  )
}

export default observer(HistoryOrderFarmPage)