import React, { useEffect } from 'react'
import "./TrialPeriodExpired.scss";
import UpgradeFarmModal from '../upgradePackage/components/UpgradeFarmModal';
import { upgradePackageStore } from '../upgradePackage/UpgradePackageStore';
import { toastUtil } from '../../common/utils/ToastUtil';

function TrialPeriodExpired() {
    let packageName = localStorage.getItem("packageName");

    return (
        <div className='trial-period-expired calc-h-vh-80px'>
            <div className='expired-contert w-800px h-400px bg-white rounded-10px  '>
                <div className='px-150 py-68px d-flex flex-column bg-white align-items-center'>
                    <img src="/assets/ico/calendar-remove.svg" width={115} alt="" />
                    <span className='mt-16px fs-24px fw-600 mb-16px'>Hết hạn dùng thử Omfarm!</span>
                    <div className='d-flex'>
                        <span>Thời gian sử dụng gói</span>
                        <span className='text-lowercase px-3px fw-700'>{packageName ? packageName : ""}</span>
                        <span>của quý khách đã hết.</span>
                    </div>
                    <span>Vui lòng nâng cấp lên bản chính thức để không bị gián đoạn công việc.</span>
                    <button
                        className='btn button_add text-white fs-16px mt-16px rounded-4px'
                        data-toggle="modal"
                        data-target={`#upgrade-farm-modal`}
                        onClick={() => {
                            upgradePackageStore.isOpenHistory = false
                            upgradePackageStore.upgradeNow()
                        }}
                    >
                        Nâng cấp ngay
                    </button>
                </div>
            </div>
            <UpgradeFarmModal />
        </div>
    )
}

export default TrialPeriodExpired