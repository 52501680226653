/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import SelectAnt from '../../../common/component/form/Select';
import { Button, Col, Input, InputNumber, Row } from 'antd';
import { calculateYScroll, checkExpirationTime, convertPrice, convertToLocaleVN } from '../../../common/utils/Utils';
import ItemResources from './ItemResources';
import "../UpgradePackage.scss";
import { upgradePackageStore } from '../UpgradePackageStore';
import { observer } from 'mobx-react';
import moment from 'moment';
import Loading from '../../../common/component/Loading';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IPackagePrice, IQRCodePackage } from '../InterfaceUpgradePackage';
import { TYPE_QR_PACKAGE } from '../UpgradePackageConstant';
import TextOverflowEllipsis from '../../../common/component/TextOverflowEllipsis';

function BuyPackage() {
    const { t } = useTranslation();
    const { packageId } = useParams();
    const navigate = useNavigate()
    const location = useLocation();

    const { exportQRProduct, exportQrBatchUnlimited, exportQrBatch } = upgradePackageStore.requestListData[0]?.isFarmPackage ? upgradePackageStore.requestListData[0] : upgradePackageStore.farmPackageBuyInfo.packageFarm?.packageData || {}
    let isHorizontal = exportQRProduct && !exportQrBatchUnlimited && exportQrBatch;

    useEffect(() => {
        init();
    }, [packageId, location?.state])

    const init = async () => {
        await Promise.all([
            upgradePackageStore.getFarmPackage(),
            upgradePackageStore.getQrCodeProduct(),
            upgradePackageStore.getQrCodeBatches()
        ])

        if (location?.state?.requestListData) {
            upgradePackageStore.requestListData = JSON.parse(location.state?.requestListData || '[]')
        } else {
            packageId && upgradePackageStore.getDetailPackage(packageId, JSON.parse(location?.state?.selectTypePackage))
        }
    }

    const increaseQuantity = (item: any) => {
        item.quantity += 1;
    };

    const decreaseQuantity = (item: any) => {
        if (!(item.quantity > 1)) return;
        item.quantity -= 1;
    };

    const sumTotalPrice = () => {
        return upgradePackageStore.requestListData.reduce((total: number, item: any) => total + (item.price * item.quantity), 0);
    }

    const handleChangeTypePackage = (item: any, e: number | string) => {
        let packagePrice = item?.package?.packagePrices.find((packagePrice: IPackagePrice) => packagePrice?.id === e)
        item.typePackage = e
        item.price = packagePrice.price
    }

    const checkDataQR = () => {
        return isHorizontal
            ? upgradePackageStore.listQrCodeProduct.concat(upgradePackageStore.listQrCodeBatches)
            : exportQRProduct
                ? upgradePackageStore.listQrCodeProduct
                : !exportQrBatchUnlimited && exportQrBatch ? upgradePackageStore.listQrCodeBatches : []
    }

    const handleChangePackage = (item: any, e: number | string) => {
        item.packageId = e;
        if (item?.isFarmPackage) {
            let _package = upgradePackageStore.listFarmPackage.find(itemPackage => itemPackage?.id === e);
            upgradePackageStore.payNow(_package)
        } else {
            let qrCodePackage: any = checkDataQR().find(qrCodePackage => qrCodePackage?.id === e);
            item.price = qrCodePackage.price;
            item.qrCodeLimit = qrCodePackage.qrCodeLimit;
            item.type = qrCodePackage.type;
        }
    }

    const checkListQrCodePackage = (itemOrder: any) => {
        return checkDataQR().filter((item: IQRCodePackage) =>
            !upgradePackageStore.requestListData.some((removeItem: any) =>
                removeItem?.isFarmPackage
                    ? false
                    : itemOrder?.packageId === removeItem.packageId ? false : removeItem.packageId === item?.id
            )
        );
    }

    return (
        <div className={`${checkExpirationTime().isExpired ? "is-expired" : ""} bg-F3F4F5 p-10px buy-package h-100`}>
            <Row gutter={[10, 8]} className='h-100'>
                <Col xs={19} className='d-flex gap-10px flex-column'>
                    <div className='p-12px bg-white'>
                        <p className='fs-16px color-00953D fw-500'>{t("selectProdPackage")}</p>
                        <div className={`${isHorizontal ? "calc-max-h-vh-555px" : "h-250px"} mt-10px min-h-250px overflow-auto order-buy-package`}>
                            <table className='align-middle w-100 table-hover table-rounded border gy-7 gs-7 table-sticky-header'>
                                <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <th className="text-center">STT</th>
                                        <th className="text-center min-w-200px max-w-250px">{t("products/Services")}</th>
                                        <th className="text-center w-70px">{t("orderUnit")}</th>
                                        <th className="text-center min-w-170px">{t("orderType")}</th>
                                        <th className="text-center w-150px">{t("quantityOrder")}</th>
                                        <th className="text-end">{t("unitPrice")}</th>
                                        <th className="text-end">{t("totalAmount")}</th>
                                        <th className="text-center"></th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {upgradePackageStore.requestListData.map((item: any, i: number) => {
                                        return (
                                            <tr key={i}>
                                                <td className="text-center">{i + 1}</td>
                                                <td>
                                                    {
                                                        item?.giftQRCode === "giftQRCode"
                                                            ? <Input
                                                                className='h-46px border-F3F3F3'
                                                                value={item?.name}
                                                                readOnly={true}
                                                            /> : <SelectAnt
                                                                options={
                                                                    item?.isFarmPackage
                                                                        ? upgradePackageStore.listFarmPackage
                                                                        : checkListQrCodePackage(item)
                                                                }
                                                                isError={item?.error?.packageId}
                                                                value={item.packageId}
                                                                onChange={(e: any) => handleChangePackage(item, e)}
                                                            />
                                                    }

                                                </td>
                                                <td className="text-center w-70px">{t("package")}</td>
                                                <td className='max-w-170px'>
                                                    {
                                                        item?.isFarmPackage
                                                            ? <SelectAnt
                                                                disabled={!item?.package}
                                                                value={item.typePackage}
                                                                onChange={(e: any) => handleChangeTypePackage(item, e)}
                                                                convertDataSelectAnt={upgradePackageStore.convertDataSelectAnt}
                                                                options={item?.package?.packagePrices || []}
                                                            />
                                                            : <Input
                                                                className='h-46px border-F3F3F3'
                                                                value={item?.type === TYPE_QR_PACKAGE.PACKAGE_EXPORT_BATCH ? t("qrInBatches") : t("qrByProduct")}
                                                                readOnly={true}
                                                            />

                                                    }
                                                </td>
                                                <td className="text-center min-w-150px">
                                                    <Input.Group compact>
                                                        <Button
                                                            onClick={() => decreaseQuantity(item)}
                                                            className='btn-quatity fs-24px'
                                                            disabled={item?.isFarmPackage || item?.giftQRCode}>
                                                            -
                                                        </Button>
                                                        <InputNumber value={item.quantity} controls={false} className='quatity' disabled={item?.isFarmPackage || item?.giftQRCode} />
                                                        <Button
                                                            onClick={() => increaseQuantity(item)}
                                                            className='btn-quatity fs-24px'
                                                            disabled={item?.isFarmPackage || item?.giftQRCode}
                                                        >+
                                                        </Button>
                                                    </Input.Group>
                                                </td>
                                                <td className="text-end">{convertPrice(item.price) || 0}</td>
                                                <td className="text-end">{convertPrice(item.quantity * item.price) || 0}</td>
                                                <td className="text-center">
                                                    {!(item?.isFarmPackage || item?.giftQRCode || i === 0) &&
                                                        <img src={`/assets/ico/delete_cricle.svg`}
                                                            alt=""
                                                            width={24}
                                                            onClick={() => upgradePackageStore.requestListData.splice(i, 1)}
                                                        />
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        exportQRProduct &&
                        <div className={`${isHorizontal ? "" : "flex-1"} rq-code p-12px bg-white`}>
                            <p className='fs-16px color-00953D fw-500'>{t("qrByProduct")}</p>
                            <div className={`${isHorizontal ? "overflow-x" : "flex-wrap overflow-y calc-max-h-vh-510px"} list-rq-code d-flex gap-10px`}>
                                {
                                    upgradePackageStore?.isLoading ? <Loading /> : (upgradePackageStore.listQrCodeProduct || []).map((resources, i) =>
                                        <ItemResources key={i} item={resources} isHorizontal={isHorizontal} />
                                    )
                                }
                            </div>
                        </div>
                    }
                    {
                        !exportQrBatchUnlimited && exportQrBatch &&
                        <div className={`${isHorizontal ? "" : "flex-1"} rq-code p-12px bg-white`}>
                            <p className='fs-16px color-00953D fw-500'>{t("qrInBatches")}</p>
                            <div className={`${isHorizontal ? "overflow-x" : "flex-wrap overflow-y calc-max-h-vh-510px"} list-rq-code d-flex gap-10px`}>
                                {
                                    upgradePackageStore?.isLoading ? <Loading /> : (upgradePackageStore.listQrCodeBatches || []).map((resources, i) =>
                                        <ItemResources key={i} item={resources} isHorizontal={isHorizontal} />
                                    )
                                }
                            </div>
                        </div>
                    }
                </Col>
                <Col xs={5}>
                    <div className='p-16px bg-white'>
                        <p className='fs-16px color-00953D fw-500'>{t("paymentInformation")}</p>
                        <div className='d-flex justify-content-between align-items-center'>
                            <span>{t("createdAt")}</span>
                            <span>{moment(new Date()).format("DD/MM/YYYY")}</span>
                        </div>
                        <hr className='bg-7C7B7B' />
                        <div className='d-flex justify-content-between align-items-center h-20px'>
                            <span className='flex-shrink-0 mr-3px'>{t("totalAmount")}</span>
                            <div className='calc-max-w-60px text-align-end'>
                                <TextOverflowEllipsis className='d-inline-block fw-600' value={convertToLocaleVN(sumTotalPrice() || 0)} />
                            </div>
                        </div>
                        <button
                            className='button btn-confirm mt-10px w-100 rounded-6px'
                            onClick={() => upgradePackageStore.paySubmit(navigate)}
                            disabled={upgradePackageStore.isLoadingBt}
                        >
                            {t("pay")}
                        </button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default observer(BuyPackage)