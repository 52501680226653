import React, {useRef} from 'react';
import {observer} from "mobx-react";
import Input from "../../../common/component/form/Input";
import {Moment} from "../../../common/utils/Moment";
import DatePickerSingle from "../../../common/component/DatePickerSingle";
import {numberUtil} from "../../../common/utils/NumberUtil";
import Barcode from "react-barcode";
import './AddOrUpdateTracking.scss'
import {useReactToPrint} from "react-to-print";
import html2canvas from "html2canvas";
import {slug, urlImage} from "../../../common/utils/Utils";
import {trackingSeafoodStore} from "../TrackingSeafoodStore";
import ButtonGlobal from "../../../common/component/ButtonGlobal";
import TextEditor from "../../../common/component/TextEditor";
import uploadFileHelper from "../../../common/helpers/UploadFileHelper";
import InputPhone from '../../../common/component/form/InputPhone';
import { REGEX } from '../../../common/constants/Constants';



const AddOrUpdateTracking = observer(() => {
    const componentRef: any = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })

    const handleHarvestDate = async (startDate: Date) => {
        trackingSeafoodStore.dataRequest.details.harvestDate = startDate ? startDate.getTime() : ''
        trackingSeafoodStore.minEndDate = Moment.plusDays(startDate, 1)
    }

    const uploadImg = (e: any) =>{
        if(!trackingSeafoodStore.dataRequest.unitInformation.certificates){
            trackingSeafoodStore.dataRequest.unitInformation.certificates = []
        }
        trackingSeafoodStore.dataRequest.unitInformation.certificates.push(e)
    }



    const downloadBarcode = async () => {
        const element = componentRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/png');
        const link = document.createElement('a');

        link.href = data;
        link.download = 'barcodeTracking.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    };

    let {details, productInformation, unitInformation, productQuality} = trackingSeafoodStore.dataRequest

    return (
        <div className="modal fade modal_full" id="addEditTrackingSeaFood" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content border-0">
                    <button type="button" className="close d-flex align-items-center" id="close_form_access"
                            data-dismiss="modal" aria-label="Close">
                        <span>&times;</span>
                        <p className="m-0">Đóng</p>
                    </button>
                    <div className="modal-header pt-0">
                        <h5 className="modal-title w-100 text-center">Truy xuất thủy sản</h5>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="row">
                            <div className="col-6 pr-5">
                                <div className="info mb-4">
                                    <div className="title_top mb-4">
                                        <h4 className="color-green">Thông tin sản phẩm</h4>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Tên sản phẩm <span className="text-danger">*</span></label>
                                        <Input value={productInformation.name}
                                               isError={trackingSeafoodStore.errors.name}
                                               onChange={(e: any) => productInformation.name = e.currentTarget.value}
                                               placeholder="Nhập tên sản phẩm"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4">
                                        <label>Diện tích <span className="text-danger">*</span></label>
                                        <Input value={productInformation.area}
                                               isError={trackingSeafoodStore.errors.area}
                                               onChange={(e: any) => productInformation.area = e.currentTarget.value}
                                               placeholder="Nhập diện tích"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Giống <span className="text-danger">*</span></label>
                                        <Input value={productInformation.productType}
                                               isError={trackingSeafoodStore.errors.productType}
                                               onChange={(e: any) => productInformation.productType = e.currentTarget.value}
                                               placeholder="Nhập giống"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Mã số lô <span className="text-danger">*</span></label>
                                        <Input
                                            value={productInformation.productionBatch}
                                            isError={trackingSeafoodStore.errors.productionBatch}
                                            onChange={(e: any) => productInformation.productionBatch = e.currentTarget.value}
                                            placeholder="Nhập mã số lô"/>
                                    </div>
                                    <div className="title_top mb-4">
                                        <h4 className="color-green">Chất lượng sản phẩm</h4>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Màu sắc</label>
                                        <Input
                                            value={productQuality.color}
                                            onChange={(e: any) => productQuality.color = e.currentTarget.value}
                                            placeholder="Nhập màu sắc"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Độ tươi</label>
                                        <Input
                                            value={productQuality.freshness}
                                            onChange={(e: any) => productQuality.freshness = e.currentTarget.value}
                                            placeholder="Nhập độ tươi"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Chứng nhận</label>
                                        <Input
                                            value={productQuality.certification}
                                            onChange={(e: any) => productQuality.certification = e.currentTarget.value}
                                            placeholder="Nhập chứng nhận"/>
                                    </div>
                                </div>

                                <div className="info">
                                    <div className="title_top mb-4">
                                        <h4 className="color-green">Thông tin chi tiết</h4>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Ngày thu hoạch <span className="text-danger">*</span></label>
                                        <DatePickerSingle
                                            selected={details.harvestDate}
                                            isError={trackingSeafoodStore.errors.harvestDate}
                                            onChange={handleHarvestDate} placeholder="Chọn ngày thu hoạch"/>
                                    </div>

                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Khối lượng tịnh <span className="text-danger">*</span></label>
                                        <Input
                                            value={details.netWeight} isError={trackingSeafoodStore.errors.netWeight}
                                            onChange={(e: any) => details.netWeight = e.currentTarget.value}
                                            placeholder="Nhập khối lượng tịnh"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Hướng dẫn sử dụng <span className="text-danger">*</span></label>
                                        <Input
                                            value={details.userManual} isError={trackingSeafoodStore.errors.userManual}
                                            onChange={(e: any) => details.userManual = e.currentTarget.value}
                                            placeholder="Nhập hướng dẫn sử dụng"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Hướng dẫn bảo quản <span className="text-danger">*</span></label>
                                        <Input value={details.storageInstructions}
                                               isError={trackingSeafoodStore.errors.storageInstructions}
                                               onChange={(e: any) => details.storageInstructions = e.currentTarget.value}
                                               placeholder="Nhập hướng dẫn bảo quản"/>
                                    </div>
                                    <div>
                                        <label className="mb-4">Mô tả chi tiết sản phẩm</label>
                                        <TextEditor content={trackingSeafoodStore.dataRequest.details.description} height={250} onChange={(e: any) => trackingSeafoodStore.dataRequest.details.description = e}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 pl-5">
                                <div className="info pt-4">
                                    <div className="title_top mb-4">
                                        <h4 className="color-green">Thông tin đơn vị</h4>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Tên đại diện <span className="text-danger">*</span></label>
                                        <Input
                                            value={unitInformation.representativeName}
                                            isError={trackingSeafoodStore.errors.representativeName}
                                            onChange={(e: any) => unitInformation.representativeName = e.currentTarget.value}
                                            placeholder="Nhập tên đại diện"/>
                                    </div>

                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Số điện thoại <span className="text-danger">*</span></label>
                                        <InputPhone
                                            value={unitInformation.phone}
                                            isError={trackingSeafoodStore.errors.phone}
                                            isValid={trackingSeafoodStore.errors.phone && unitInformation.phone}
                                            onChange={(e: any) => {
                                                unitInformation.phone = e;
            
                                            }}
                                                placeholder="Nhập số điện thoại "
                                            />
                                    </div>

                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Email </label>
                                        <Input value={unitInformation.email}
                                               onChange={(e: any) => unitInformation.email = e.currentTarget.value}
                                               placeholder="Nhập email"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Địa chỉ <span className="text-danger">*</span></label>
                                        <Input value={unitInformation.address}
                                               isError={trackingSeafoodStore.errors.address}
                                               onChange={(e: any) => unitInformation.address = e.currentTarget.value}
                                               placeholder="Nhập địa chỉ"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Website</label>
                                        <Input value={unitInformation.website}
                                               onChange={(e: any) => unitInformation.website = e.currentTarget.value}
                                               placeholder="Nhập website"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4  ">
                                        <label>Loại hình <span className="text-danger">*</span></label>
                                        <Input value={unitInformation.businessDescription}
                                               isError={trackingSeafoodStore.errors.businessDescription}
                                               onChange={(e: any) => unitInformation.businessDescription = e.currentTarget.value}
                                               placeholder="Nhập loại hình"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4">
                                        <label>Sản phẩm chính <span className="text-danger">*</span></label>
                                        <Input value={unitInformation.mainProduct}
                                               isError={trackingSeafoodStore.errors.mainProduct}
                                               onChange={(e: any) => unitInformation.mainProduct = e.currentTarget.value}
                                               placeholder="Nhập sản phẩm chính"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4">
                                        <label>Tổng doanh thu </label>
                                        <Input value={unitInformation.totalRevenue}
                                               onChange={(e: any) => unitInformation.totalRevenue = e.currentTarget.value}
                                               placeholder="Nhập tổng doanh thu"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4">
                                        <label>Thị trường chính</label>
                                        <Input value={unitInformation.mainMarket}
                                               onChange={(e: any) => unitInformation.mainMarket = e.currentTarget.value}
                                               placeholder="Nhập thị trường chính"/>
                                    </div>
                                    <div className="d-flex flex-wrap mb-4">
                                        <label>Tổng nhân viên </label>
                                        <Input value={unitInformation.totalLabor}
                                               onChange={(e: any) => unitInformation.totalLabor = e.currentTarget.value}
                                               placeholder="Nhập tổng nhân viên"/>
                                    </div>
                                    <div className="mb-4">
                                        <label>Giấy chứng nhận </label>
                                        <div className="scroll_certificate">
                                            <div className="list_certificate">
                                                <div className="upload" title="Upload giấy chứng nhận">
                                                    <img src="/assets/ico/ico_cloud_upload.svg" alt=""/>
                                                    <input accept='image/jpeg, image/jpg, image/png, image/svg' type='file'
                                                           onChange={async (e: any) => await uploadFileHelper(e, uploadImg)}/>
                                                </div>
                                                {unitInformation.certificates && unitInformation.certificates.map((item: any, i: number) => {
                                                    return (
                                                        <div className="item" key={i}>
                                                            <img src={urlImage(item)} alt=""/>
                                                            <div className="close_item"
                                                                 onClick={() => unitInformation.certificates.splice(i, 1)}>
                                                                <img src="/assets/ico/ico_close.svg" alt=""/>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="barcode-container mt-4 mb-5">
                                        <div className="barcode-display-content">
                                            <div className="barcode">
                                                <div className="barcode-content" ref={componentRef}>
                                                    {trackingSeafoodStore.dataRequest.barcode ?
                                                        <Barcode value={`${trackingSeafoodStore.dataRequest.barcode}`}
                                                                 format="EAN13"/> :
                                                        <div className="no_barcode">
                                                            <span className="title">Bạn chưa có mã vạch</span>
                                                        </div>}
                                                </div>
                                            </div>
                                            <input type="text"
                                                   className="text-center mt-2 input_barcode border-right-0 border-top-0 border-left-0"
                                                   value={trackingSeafoodStore.dataRequest.barcode ?? ''}
                                                   onChange={(e: any) => {if (e.currentTarget.value.length <= 13) {trackingSeafoodStore.dataRequest.barcode = numberUtil.regexNumber(e)}}} placeholder="Nhập mã vạch ..."/>
                                        </div>
                                        <div className="barcode-display-btn">
                                            {trackingSeafoodStore.dataRequest.id ?
                                                <a href={`${window.location.origin}/xem-ket-qua-truy-xuat-hai-san/${slug(productInformation.name)}/${trackingSeafoodStore.dataRequest.id}`} target="_blank">
                                                    <ButtonGlobal text="Xem Kết Quả"/>
                                                </a> : <ButtonGlobal text="Xem Kết Quả" disabled={true}/>}
                                            <ButtonGlobal text="Tải Mã Vạch" ico="download"
                                                          disabled={!trackingSeafoodStore.dataRequest.barcode}
                                                          onClick={downloadBarcode}/>
                                            <ButtonGlobal text="In Mã Vạch" ico="ico_printer"
                                                          disabled={!trackingSeafoodStore.dataRequest.barcode}
                                                          onClick={handlePrint}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end mt-5">
                            <button type="button" className="btn send-request"
                                    onClick={() => trackingSeafoodStore.addOrUpdateTracking()}
                                    disabled={trackingSeafoodStore.isLoadingBt}>Lưu
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

})

export default AddOrUpdateTracking;