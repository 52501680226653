import { observer } from "mobx-react";
import React from 'react'
import { trackingDetailStore } from "../TrackingDetailStore";
import { dateUtils } from "../../../../common/utils/DateUtils";
import { toastUtil } from "../../../../common/utils/ToastUtil";
import { number_format } from "../../../../common/utils/Utils";
import NoContent from "../../../../common/component/NoContent";
import { TYPE_QR_PACKAGE } from "../../../upgradePackage/UpgradePackageConstant";
import "../TrackingBatchStyle.scss"


const HistoryQr = React.forwardRef(() => {
    let showMore = React.createRef<HTMLDivElement>();


    const handleOnScroll = (e: any) => {
        if (showMore.current) {
            const self = e.currentTarget;
            if (($(self).height() as number + self.scrollTop) === (self.scrollHeight - 20)) {
                trackingDetailStore.pageHistory++
                trackingDetailStore.pageHistory < trackingDetailStore.totalPageHistory && trackingDetailStore.readMore();
            }
        }
    }

    const copyLink = (id: any) => {
        var copyText: any = document.getElementById(`path_link_${id}`);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        toastUtil.success('Sao chép thành công!')
    }

    const changTab = async (type: any) => {
        trackingDetailStore.tabActive = type;
        await trackingDetailStore.getHistoryQr(trackingDetailStore.dataRequest.id)
    }

    return (
        <div className="modal fade" id="history_qr" role="dialog" aria-hidden="true">
            <div className="modal-dialog" style={{ maxWidth: 880 }} role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Lịch sử xuất</h5>
                        <button type="button" className="close" id="close_history_qr" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="form">
                            <div className="tab_header d-flex align-items-center">
                                <div className={`tab ${trackingDetailStore.tabActive === TYPE_QR_PACKAGE.PACKAGE_EXPORT_PRODUCT ? 'active' : ''}`}
                                    onClick={() => changTab(TYPE_QR_PACKAGE.PACKAGE_EXPORT_PRODUCT)}
                                >
                                    <span>QR theo Sản phẩm</span>
                                </div>
                                <div className={`tab ${trackingDetailStore.tabActive === TYPE_QR_PACKAGE.PACKAGE_EXPORT_BATCH ? 'active' : ''}`}
                                    onClick={() => changTab(TYPE_QR_PACKAGE.PACKAGE_EXPORT_BATCH)}
                                >
                                    <span>QR theo Lô</span>
                                </div>

                            </div>
                            <p className="text-center my-2" style={{ fontSize: 16 }}>
                                {
                                    trackingDetailStore.tabActive === TYPE_QR_PACKAGE.PACKAGE_EXPORT_PRODUCT
                                        ? <>
                                            Bạn đang có
                                            <span className="color-green font-weight-medium px-3px">{number_format(trackingDetailStore.dataRequest?.packageQRCodeUsed?.remainingExportProduct) || 0}</span>
                                            mã theo sản phẩm được tạo!
                                        </> : !trackingDetailStore.dataRequest.packageData?.exportQrBatchUnlimited && <>
                                            Bạn đang có
                                            <span className="color-green font-weight-medium px-3px">{number_format(trackingDetailStore.dataRequest?.packageQRCodeUsed?.remainingExportBatch) || 0}</span>
                                            mã theo lô được tạo!
                                        </>
                                }
                            </p>
                            {trackingDetailStore.listHistoryQr && trackingDetailStore.listHistoryQr.length > 0 ?
                                <div className="list_history" ref={showMore} onScroll={(e: any) => handleOnScroll(e)}>
                                    {trackingDetailStore.listHistoryQr.map((item, i) => {
                                        return (
                                            <div className={`item ${item.isExpired && 'isExpired'}`} title={item.isExpired ? 'Đường link hết hạn' : ''} key={i}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span>{dateUtils.formatDate(item.createdAt, 'DD/MM/YYYY, HH:mm')}</span>
                                                    <span className="color-green">{number_format(item.qty)} Mã QR</span>
                                                </div>
                                                <div className="d-flex action_ align-items-center">
                                                    <input type="text" id={`path_link_${item.id}`} onChange={() => { }}
                                                        value={`${process.env.REACT_APP_API_DOMAIN}/farm/v1/tracking_batch/${item.id}${item.path}`} />
                                                    <button onClick={() => copyLink(item.id)} disabled={item.isExpired}
                                                        title={item.isExpired ? 'Đường link hết hạn' : 'Sao chép'}>
                                                        <img src="/assets/ico/ico_copy.png" alt="" />
                                                    </button>
                                                    <button disabled={trackingDetailStore.isLoadingBt || item.isExpired}
                                                        onClick={() => trackingDetailStore.downloadQR(item.id, item.path)}
                                                        title={item.isExpired ? 'Đường link hết hạn' : 'Tải mã qr'}>
                                                        <img src="/assets/ico/ico_download.png" alt="" />
                                                    </button>
                                                    <button disabled={trackingDetailStore.isLoadingBt || item.isExpired} title={item.isExpired ? 'Đường link hết hạn' : 'In mã qr'} onClick={() => {
                                                        trackingDetailStore.dataRequest.id = item.trackingQrBatchId;
                                                        trackingDetailStore.errorBackgroundPrint = ''
                                                        trackingDetailStore.dataRequest.qrFile = item.path
                                                    }} data-dismiss="modal" data-toggle="modal" data-target="#qr_print_code">
                                                        <img src="/assets/ico/ico_printer.png" alt="" />
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}

                                </div> : <div className="mt-2"><NoContent widthImg={96} height={312}
                                    message="Bạn chưa có mã nào!" /></div>}
                            <p className="mt-3 mb-4 font-italic" style={{ color: 'red', fontSize: 14 }}>Chú ý*: Đường link
                                tải về tồn tại trong 24h</p>
                            <div className="d-flex mt-4 justify-content-end">
                                <button className="btn send-request" data-dismiss="modal" data-toggle="modal"
                                    data-target="#qr_code_tracking" style={{ height: 42 }}
                                    onClick={() => trackingDetailStore.clearDownloadQR()}>Tạo mã mới
                                </button>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
})

export default observer(HistoryQr)