/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { Col, Row } from "antd";
import { approvedPackageStore, IParams } from "../ApprovedPackageStore";
import { checkLength } from "../../../../common/utils/Utils";
import { dateUtils } from "../../../../common/utils/DateUtils";
import SelectAnt from "../../../../common/component/form/Select";
import DateRangePickerAnt from "../../../../common/component/form/DateRangePickerAnt";

interface IFilter {
    params: IParams,
    callback: () => void,
    callbackClear: () => void,
    handleClose: () => void
}
const FilterApprovedPackage: React.FC<IFilter> = ({ params, callback, callbackClear }) => {

    const handelChangeSelect = useCallback((name: string, e: any) => {
        (params as any)[name] = e;
        callback();
    }, [params])

    const handelChangeDateRange = useCallback((nameStartDate: string, nameEndDate: string, dates: any) => {
        (params as any)[nameStartDate] = checkLength(dates) ? dateUtils.convertTime(dates[0]) : null;
        (params as any)[nameEndDate] = checkLength(dates) ? dateUtils.convertMaxTimeDayjs(dates[1]) : null;
        callback();
    }, [params])

    return (
        <div className={`component_filter w-410px px-13px pt-20px pb-10px`}>
            <Row gutter={[12, 10]}>
                <Col xs={24}>
                    <label>Trạng thái</label>
                    <SelectAnt
                        isShowClear={true}
                        value={params?.status || null}
                        onChange={(e: any) => handelChangeSelect("status", e)}
                        options={approvedPackageStore.listStatus}
                    />
                </Col>
                <Col xs={24}>
                    <label>Trạng thái thanh toán</label>
                    <SelectAnt
                        isShowClear={true}
                        value={params?.paymentState || null}
                        onChange={(e: any) => handelChangeSelect("paymentState", e)}
                        options={approvedPackageStore.listPaymentState}
                    />
                </Col>
                <Col xs={24}>
                    <label>Thời gian yêu cầu</label>
                    <DateRangePickerAnt
                        placeholder={["DD/MM/YYYY", "DD/MM/YYYY"]}
                        onChange={(dates: any) => handelChangeDateRange("startDate", "endDate", dates)}
                        allowClear
                        format={"DD/MM/YYYY"}
                        value={[params?.startDate, params?.endDate]}
                    />
                </Col>
            </Row>
            <div className="d-flex justify-content-end mt-12px">
                <button className="button_outline py-5px px-24px rounded-4px" onClick={callbackClear}>
                    Bỏ lọc
                </button>
            </div>
        </div>
    )
}

export default observer(FilterApprovedPackage);