import React from 'react';
import {observer} from "mobx-react";
import {approvedPackageStore} from "../ApprovedPackageStore";
import {Spin} from "antd";


const PopupRejectPackage = () => {


    return (
        <div className="modal fade" id="popup_reject" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{maxWidth: 650}}>
                <div className="modal-content pt-5 border-0">
                    <div className="text-center pt-6 mb-4">
                        <img src={"/assets/ico/ico_reject.svg"} alt="" />
                        <h5 className="modal-title mt-2">{`Từ chối ${approvedPackageStore.isPaymentState ? "thanh toán" : "duyệt"}`}</h5>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="action_footer d-flex justify-content-between">
                            <button className="bt_cancel" data-dismiss="modal">Hủy</button>
                            <button className="send-request bt_reject"
                                    onClick={() => approvedPackageStore.isPaymentState ? approvedPackageStore.changeStatusPaymentState(approvedPackageStore.id, 'FAILED') : approvedPackageStore.changeStatus(approvedPackageStore.id, 'REJECTED')}
                                    disabled={approvedPackageStore.isLoadingBt}>{approvedPackageStore.isLoadingBt ?  <Spin className="text-white" /> : 'Xác nhận' }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(PopupRejectPackage);