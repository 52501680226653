import React from 'react';
import { Input } from "antd";
import { numberUtil } from '../../utils/NumberUtil';

interface IProps {
    value: any,
    defaultValue?: any;
    onChange: any
    isError?: any,
    type?: any,
    disabled?: any,
    placeholder?: string,
    onKeyDown?: () => void
    iconCheck?: boolean
    maxLength?: number,
    className?: string,
    notValue?: boolean
    readOnly?: boolean
    isValid?: boolean
}


const InputPhone = (item: IProps) => {

    let _value = item?.notValue ? item?.defaultValue : item?.value
    let _isValid = item.isError && (item?.isValid || (!_value && !(typeof _value === "string" && String(_value)?.trim())))

    return (
        <div className="input-group">
            <div className='w-100'>
                {!item.iconCheck ? <Input
                    type={item.type ?? 'text'}
                    value={item.value || ""}
                    maxLength={item.maxLength ?? 10}
                    onChange={(e: any) => {
                        item.onChange(numberUtil.regexNumber(e))
                    }} disabled={item.disabled} onKeyDown={(e: any) => {
                        if (e.key === "Enter") {
                            item.onKeyDown && item.onKeyDown()
                        }
                    }}
                    className={`${item.className} ${(item.isError && !item.value) && 'border_error'} form-control form-control-lg`}
                    placeholder={item.placeholder ?? ''} /> :
                    <Input type={item.type ?? 'text'} value={item.value || ""} minLength={10} onKeyDown={(e: any) => {
                        if (e.key === "Enter") {
                            item.onKeyDown && item.onKeyDown()
                        }
                    }}
                        onChange={(e: any) => {
                            item.onChange(numberUtil.regexNumber(e))
                        }} disabled={item.disabled} maxLength={item.maxLength ?? 10}
                        suffix={<img width={21} height={20} src="/assets/ico/ico_check.svg" alt="" />}
                        className={`${item.className} ${_isValid ? 'border_error' : ""} form-control form-control-lg`}
                        placeholder={item.placeholder ?? ''} />}
            </div>
            {_isValid && (
                <p className="error">{item?.isError ?? 'Số điện thoại không được để trống!'}</p>
            )}
        </div>
    );

}

export default InputPhone;