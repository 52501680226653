import {observable} from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import {toastUtil} from "../../common/utils/ToastUtil";
import $ from "jquery";
import {Moment} from "../../common/utils/Moment";
import {trackingSeafoodService} from "./TrackingSeafoodService";
import {trackingStore} from "../tracking/TrackingStore";
import { REGEX } from "../../common/constants/Constants";

interface IDataRequest {
    id: '',
    barcode: any,
    trackingImage: '',
    images: any[],
    productQuality: {
        color: '',
        freshness: '',
        certification: ''
    },
    productInformation: {
        name: "",
        productType: '',
        area: '',
        productionBatch: ''
    },
    details: {
        harvestDate: any,
        netWeight: '',
        userManual: '',
        storageInstructions: '',
        description: any
    },
    unitInformation: {
        representativeName: "",
        email: "",
        phone: string | number,
        address: "",
        mainProduct: "",
        website: "",
        totalRevenue: '',
        mainMarket: '',
        totalLabor: '',
        businessDescription: "",
        certificates: any[]
    }
}

interface IError{
    name: any,
    productType: any,
    area: any,
    productionBatch: any,
    harvestDate: any,
    expirationDate: any,
    netWeight: any,
    userManual: any,
    storageInstructions: any,
    representativeName: any,
    phone: any,
    address: any,
    mainProduct: any,
    businessDescription: any,
}

class TrackingSeafoodStore {
    @observable isLoading: boolean = false
    @observable isLoadingBt: boolean = false
    @observable isLoadingDetail: boolean = false
    @observable warningError: boolean = false
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable type: any = ''
    @observable keyword: any = '';
    @observable searchYear: any = '';
    @observable pageHelper: any = '';
    @observable listTracking: any[] = []
    @observable dataFilter: any[] = []
    @observable minEndDate: Date = Moment.getToDay();
    @observable errors: IError = {
        name: "",
        productType: '',
        area: '',
        productionBatch: '',
        harvestDate: '',
        expirationDate: '',
        netWeight: '',
        userManual: '',
        storageInstructions: '',
        representativeName: "",
        phone: "",
        address: "",
        mainProduct: "",
        businessDescription: "",
    }
    @observable dataRequest: IDataRequest = {
        id: '',
        barcode: '',
        trackingImage: '',
        images: [],
        productQuality: {
            color: '',
            freshness: '',
            certification: ''
        },
        productInformation: {
            name: "",
            productType: '',
            area: '',
            productionBatch: ''
        },
        details: {
            harvestDate: '',
            netWeight: '',
            userManual: '',
            storageInstructions: '',
            description: ""
        },
        unitInformation: {
            representativeName: "",
            email: "",
            phone: "",
            address: "",
            mainProduct: "",
            website: "",
            totalRevenue: '',
            mainMarket: '',
            totalLabor: '',
            businessDescription: "",
            certificates: []
        }
    }

    clearError() {
        trackingSeafoodStore.errors = {
            name: "",
            productType: '',
            area: '',
            productionBatch: '',
            harvestDate: '',
            expirationDate: '',
            netWeight: '',
            userManual: '',
            storageInstructions: '',
            representativeName: "",
            phone: "",
            address: "",
            mainProduct: "",
            businessDescription: "",
        }
    }

    clearForm() {
        trackingSeafoodStore.type = 'add'
        trackingSeafoodStore.dataRequest = {
            id: '',
            barcode: '',
            trackingImage: '',
            images: [],
            productQuality: {
                color: '',
                freshness: '',
                certification: ''
            },
            productInformation: {
                name: "",
                area: '',
                productType: '',
                productionBatch: ''
            },
            details: {
                harvestDate: '',
                netWeight: '',
                userManual: '',
                storageInstructions: '',
                description: ''
            },
            unitInformation: {
                representativeName: "",
                email: "",
                phone: "",
                address: "",
                mainProduct: "",
                website: "",
                totalRevenue: '',
                mainMarket: '',
                totalLabor: '',
                businessDescription: "",
                certificates: []
            }
        }
    }


    async getTracking() {
        this.clearError()
        trackingSeafoodStore.isLoading = true
        const result = await trackingSeafoodService.getTracking()
        trackingSeafoodStore.isLoading = false
        if (result.status === HttpStatusCode.OK) {
            trackingSeafoodStore.listTracking = result.body.data
            trackingSeafoodStore.totalPage = result.body.metadata.totalPage
        }
    }


    async getDetail(id: any) {
        this.clearError()
        trackingSeafoodStore.type = 'edit'
        trackingSeafoodStore.isLoadingDetail = true
        const result = await trackingSeafoodService.getDetail(id)
        trackingSeafoodStore.isLoadingDetail = false
        if (result.status === HttpStatusCode.OK) {
            trackingSeafoodStore.dataRequest = result.body.data
            trackingSeafoodStore.dataRequest.id = result.body.id
            if(!trackingSeafoodStore.dataRequest?.details?.description){
                trackingSeafoodStore.dataRequest.details.description = ''
            }
        } else {
            toastUtil.error(result.body.message, 4)
        }
    }


    async addOrUpdateTracking() {
        this.clearError()
        let {
            id,
            productInformation,
            images,
            details, productQuality,
            trackingImage,
            barcode,
            unitInformation
        } = trackingSeafoodStore.dataRequest
        let data = {
            barcode: barcode,
            trackingImage: trackingImage,
            images: images,
            productQuality: productQuality,
            productInformation: productInformation,
            details: details,
            unitInformation: unitInformation
        }
        trackingSeafoodStore.warningError = false

        if (!productInformation.name) {
            trackingSeafoodStore.errors.name = "Tên sản phẩm không được để trống!"
            trackingSeafoodStore.warningError = true
        }

        if (!productInformation.productType) {
            trackingSeafoodStore.errors.productType = "Giống không được để trống!"
            trackingSeafoodStore.warningError = true
        }
        if (!productInformation.area) {
            trackingSeafoodStore.errors.area = "Diện tích không được để trống!"
            trackingSeafoodStore.warningError = true
        }

        if (!productInformation.productionBatch) {
            trackingSeafoodStore.errors.productionBatch = "Mã số lô không được để trống!"
            trackingSeafoodStore.warningError = true
        }

        if (!details.harvestDate) {
            trackingSeafoodStore.errors.harvestDate = "Ngày thu hoạch không được để trống!"
            trackingSeafoodStore.warningError = true
        }

        if (!details.netWeight) {
            trackingSeafoodStore.errors.netWeight = "Khối lượng tịnh không được để trống!"
            trackingSeafoodStore.warningError = true
        }

        if (!details.userManual) {
            trackingSeafoodStore.errors.userManual = "Hướng dẫn sử dụng không được để trống!"
            trackingSeafoodStore.warningError = true
        }

        if (!details.storageInstructions) {
            trackingSeafoodStore.errors.storageInstructions = "Hướng dẫn bảo quản không được để trống!"
            trackingSeafoodStore.warningError = true
        }

        if (!unitInformation.representativeName) {
            trackingSeafoodStore.errors.representativeName = "Tên đơn vị không được để trống!"
            trackingSeafoodStore.warningError = true
        }

        if (!unitInformation.phone) {
            trackingSeafoodStore.errors.phone = "Số điện thoại không được để trống!"
            trackingSeafoodStore.warningError = true
        }

        if (unitInformation.phone && !REGEX.PHONE.test(String(unitInformation.phone))) {
            trackingSeafoodStore.errors.phone = 'Vui lòng nhập đúng định dạng số điện thoại!'
        }

        if (!unitInformation.address) {
            trackingSeafoodStore.errors.address = "Address không được để trống!"
            trackingSeafoodStore.warningError = true
        }

        if (!unitInformation.businessDescription) {
            trackingSeafoodStore.errors.businessDescription = "Loại hình không được để trống!"
            trackingSeafoodStore.warningError = true
        }

        if (!unitInformation.mainProduct) {
            trackingSeafoodStore.errors.mainProduct = "Sản phẩm chính không được để trống!"
            trackingSeafoodStore.warningError = true
        }


        if (trackingSeafoodStore.warningError) {
            return
        }

        trackingSeafoodStore.isLoadingBt = true
        let result = trackingSeafoodStore.type === 'add' ?  await trackingSeafoodService.add(data) : await trackingSeafoodService.update(id, data)

        trackingSeafoodStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`${trackingSeafoodStore.type === 'add' ? 'Thêm' : 'Cập nhật'} truy xuất thành công!`, 1)
            if(result.body.id){
                trackingSeafoodStore.type = 'edit'
                trackingSeafoodStore.dataRequest.id = result.body.id
            }
            await trackingSeafoodStore.getTracking()
        } else {
            if (typeof result.body.message === "object") {
                this.errors = result.body.message
            } else {
                toastUtil.error(result.body.message, 2)
            }
        }
    }

    async uploadImages() {
        let {
            id,
            productInformation,
            images,
            details, productQuality,
            trackingImage,
            barcode,
            unitInformation
        } = trackingSeafoodStore.dataRequest
        let data = {
            barcode: barcode,
            trackingImage: trackingImage,
            images: images,
            productQuality: productQuality,
            productInformation: productInformation,
            details: details,
            unitInformation: unitInformation
        }

        trackingStore.isLoadingBt = true
        const result = await trackingSeafoodService.update(id, data)
        trackingStore.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success('Cập nhật hình ảnh thành công!', 1)
            $('#close_upload_images_seafood').trigger('click');
        } else {
            toastUtil.error('Cập nhật hình ảnh thất bại', 3)
        }
    }


}

export const trackingSeafoodStore = new TrackingSeafoodStore()
