/* eslint-disable array-callback-return */
import { observable } from "mobx";
import HttpStatusCode from "../../common/constants/HttpErrorCode";
import { historyOrderFarmService } from "./HistoryOrderFarmService";
import { TIMES, TYPE_QR_PACKAGE } from "../upgradePackage/UpgradePackageConstant";
import { convertPrice } from "../../common/utils/Utils";
import { toastUtil } from "../../common/utils/ToastUtil";

class HistoryOrderFarmStore {
    @observable id: number | string = ''
    @observable tabActive: any = ''
    @observable page: number = 0
    @observable totalPage: number = 0
    @observable isLoading: boolean = false
    @observable paymentState: string = ""
    @observable isLoadingBt: boolean = false
    @observable listHistoryOrder: any[] = []
    @observable dataRequest: any = {}
    @observable listDataRequest: any = []

    async getHistoryOrderFarm(size?: number) {
        this.isLoading = true;
        const result = await historyOrderFarmService.getHistoryOrderFarm();
        this.isLoading = false;
        if (result.status === HttpStatusCode.OK) {
            this.listHistoryOrder = result.body.data;
            this.totalPage = result.body.metadata.totalPage
        }
    }

    clearParam() { }

    async getDetail(id: number) {
        const result = await historyOrderFarmService.getDetail(id)
        if (result.status === HttpStatusCode.OK) {
            let item = result.body || {};
            this.dataRequest = item;

            let itemBundle = item?.packagePrice ? {
                name: item?.packagePrice?.bundle?.name,
                type: `${item?.packagePrice?.validityPeriod || ""} ${TIMES.find(time => time.type === item?.packagePrice?.timeUnit)?.name || ""}`,
                quantity: 1,
                price: item?.packagePrice?.price,
            } : null;

            let giftQRCodeExportBatch = item?.packagePrice?.bundle?.giftQRCodeExportBatch?.qrCodeLimit > 0 ? {
                ...item?.packagePrice?.bundle?.giftQRCodeExportBatch,
                giftQRCode: "giftQRCode",
                type: item?.packagePrice?.bundle?.giftQRCodeExportBatch?.type === TYPE_QR_PACKAGE.PACKAGE_EXPORT_BATCH ? "qrInBatches" : "qrByProduct",
                quantity: 1,
                packageId: item?.packagePrice?.bundle?.giftQRCodeExportBatch?.id,
            } : null

            let giftQRCodeExportProduct = item?.packagePrice?.bundle?.giftQRCodeExportProduct?.qrCodeLimit > 0 ? {
                ...item?.packagePrice?.bundle?.giftQRCodeExportProduct,
                giftQRCode: "giftQRCode",
                type: item?.packagePrice?.bundle?.giftQRCodeExportProduct?.type === TYPE_QR_PACKAGE.PACKAGE_EXPORT_BATCH ? "qrInBatches" : "qrByProduct",
                quantity: 1,
                packageId: item?.packagePrice?.bundle?.giftQRCodeExportProduct?.id,
            } : null

            let itemPackageQRCodes = item.packageQRCodes.map((qrCode: any) => {
                return {
                    ...qrCode?.packageQRCode,
                    quantity: qrCode?.quantity,
                    type: qrCode?.packageQRCode?.type === TYPE_QR_PACKAGE.PACKAGE_EXPORT_BATCH ? "qrInBatches" : "qrByProduct",
                    price: qrCode?.packageQRCode?.price,
                    name: qrCode?.packageQRCode?.name || `Thêm ${convertPrice(qrCode?.packageQRCode?.qrCodeLimit) || 0} mã QR Code `
                }
            })

            this.listDataRequest = [
                ...(itemBundle ? [itemBundle] : []),
                ...(giftQRCodeExportBatch ? [giftQRCodeExportBatch] : []),
                ...(giftQRCodeExportProduct ? [giftQRCodeExportProduct] : []),
                ...itemPackageQRCodes
            ]
        }
    }

    async changeStatus(id: number | string, status: string) {
        this.isLoadingBt = true
        const result = await historyOrderFarmService.changeStatus(id, status)
        this.isLoadingBt = false
        if (result.status === HttpStatusCode.OK) {
            toastUtil.success(`Hủy yêu cầu mua gói thành công`)
            this.listHistoryOrder.map((item: any) => {
                if (item.id == id) {
                    item.status = status
                    item.approveAt = new Date()
                }
            })
            $('.button.btn-cancel').trigger('click')
        } else toastUtil.error(result.body.message)

    }
}

export const historyOrderFarmStore = new HistoryOrderFarmStore()