import React from 'react';
import { observer } from "mobx-react";
import { approvedPackageStore } from "../ApprovedPackageStore";
import { Spin } from "antd";


const PopupApprovePackage = () => {

    return (
        <div className="modal fade" id="popup_approve" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document" style={{ maxWidth: 650 }}>
                <div className="modal-content pt-5 border-0">
                    <div className="text-center pt-6 mb-4">
                        <img width={64} height={65} src="/assets/ico/ico_confirm.svg" alt="" />
                        <h5 className="modal-title mt-2">{`Xác nhận ${approvedPackageStore.isPaymentState ? "thanh toán" : "duyệt"}`}</h5>
                    </div>
                    <div className="modal-body pt-2">
                        <div className="action_footer d-flex justify-content-between">
                            <button className="bt_cancel" data-dismiss="modal">Hủy
                            </button>
                            <button className="send-request bt_approve" onClick={() => approvedPackageStore.isPaymentState ? approvedPackageStore.changeStatusPaymentState(approvedPackageStore.id, "PAID") : approvedPackageStore.changeStatus(approvedPackageStore.id, "APPROVED")}
                                disabled={approvedPackageStore.isLoadingBt}>{approvedPackageStore.isLoadingBt ? <Spin className="text-white" /> : 'Xác nhận'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default observer(PopupApprovePackage);