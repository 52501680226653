import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Switch } from "antd";
import Loading from "../../../common/component/Loading";
import FilterComponent from "../../../common/component/filter/FilterComponent";
import { getIDParameter, sttPage } from "../../../common/utils/Utils";
import NoContent from "../../../common/component/NoContent";
import PopupConfirm from "../../../common/component/PopupConfirm";
import Paginate from "../../../common/component/Paginate";
import ButtonAdd from "../../../common/component/ButtonAdd";
import AddEditPackageQrCode from "./components/AddEditPackageQrCode";
import { qrCodePackageStore, TypeQrCode } from "./QrCodePackageStore";
import { numberUtil } from "../../../common/utils/NumberUtil";
import { useLocation } from 'react-router-dom';

const QrCodePackagePage = () => {
    const location = useLocation();
    useEffect(() => {
        qrCodePackageStore.type = qrCodePackageStore.checkType(getIDParameter(3))
        qrCodePackageStore.dataRequest.type = qrCodePackageStore.checkType(getIDParameter(3))
        qrCodePackageStore.getQrCodePackage().then()

        return () => {
            qrCodePackageStore.clearFilter()
        }
    }, [location.pathname])

    const handlePageClick = async (data: any) => {
        qrCodePackageStore.page = data.selected
        await qrCodePackageStore.getQrCodePackage()
    }

    let dataFilter: any[] = [
        {
            label: "Tìm kiếm",
            type: 'search',
            placeholder: 'Nhập từ khóa',
            value: qrCodePackageStore.name,
            onChange: (e: any) => qrCodePackageStore.name = e,
            callback: () => callApi()
        },
    ]

    const callApi = () => {
        qrCodePackageStore.page = 0
        qrCodePackageStore.getQrCodePackage().then()
    }

    return (
        <div className="enterprise_page">
            <div className="d-flex mb-4 align-items-center">
                <FilterComponent isAdmin={true} data={dataFilter}
                    buttonAdd={<ButtonAdd text="Thêm mới" className="text-white"
                        dataTarget={'add_edit_qrcode_package'}
                        onClick={() => {
                            qrCodePackageStore.clearForm()
                            qrCodePackageStore.dataRequest.isAddPackages = true;
                        }} />} />
            </div>
            <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>
                {qrCodePackageStore.isLoading ? <Loading /> : <div className='table-responsive'>
                    {qrCodePackageStore.listPackage && qrCodePackageStore.listPackage.length > 0 ?
                        <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7'>
                            <thead>
                                <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                    <th className="text-center">STT</th>
                                    <th>Tên gói</th>
                                    <th className="text-center">Số lượng</th>
                                    <th className="text-end">Giá bán</th>
                                    <th className="text-center">Trạng thái</th>
                                    <th className="text-center">Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                {qrCodePackageStore.listPackage.map((item: any, i: number) => {
                                    return (
                                        <tr key={i}>
                                            <td className="text-center">{sttPage(qrCodePackageStore.page, i)}</td>
                                            <td>{item.name}</td>
                                            <td className="text-center">{numberUtil.number_format(item.qrCodeLimit)}</td>
                                            <td className="text-end">{numberUtil.formatNumber(item.price)}</td>
                                            <td className="text-center">
                                                <Switch title={!item.isActive ? "Bật" : "Ngưng"}
                                                    checked={item.isActive}
                                                    onChange={() => qrCodePackageStore.changeState(item.id, item.isActive)}
                                                />
                                            </td>
                                            <td width='10%'>
                                                <div className="action">
                                                    <button className="bt_action" title={"Chỉnh sửa"}
                                                        data-toggle="modal" data-target="#add_edit_qrcode_package"
                                                        onClick={() => qrCodePackageStore.getDetail(item.id)}>
                                                        <img src="/assets/ico/action/ico_edit.svg" alt="" />
                                                    </button>
                                                    <button className="bt_action" title={"Xóa"}
                                                        onClick={() => qrCodePackageStore.id = item.id}
                                                        data-toggle="modal" data-target="#popup_confirm">
                                                        <img src="/assets/ico/action/ico_trash.svg" alt="" />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table> : <NoContent />}

                    <AddEditPackageQrCode />
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate changePage={handlePageClick} totalPage={qrCodePackageStore.totalPage}
                            currentPage={qrCodePackageStore.page} />
                    </div>
                    <PopupConfirm isLoading={qrCodePackageStore.isLoading} action={() => qrCodePackageStore.delete()} />
                </div>}
            </div>
        </div>
    )
}

export default observer(QrCodePackagePage)