import React from 'react';
import {observer} from "mobx-react";
import {numberUtil} from "../../../../common/utils/NumberUtil";
import Input from "../../../../common/component/form/Input";
import {distributorStore} from "../DistributorStore";
import InputPhone from "../../../../common/component/form/InputPhone";


const AddEditDistributor = () => {

    return (
        <div className="modal fade" id="add_edit_distributor" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Thêm nhà phân phối</h5>
                        <button type="button" className="close" id="close_add_edit_distributor" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body pt-2 pb-0">
                        <div className="form-group">
                            <label>Tên nhà phân phối <span className="text-danger">*</span></label>
                            <Input isError={distributorStore.errors.name} value={distributorStore.dataRequest.name}
                                   onChange={(e: any) => distributorStore.dataRequest.name = e.currentTarget.value}
                                   placeholder="Nhập tên nhà phân phối"/>
                        </div>
                        <div className="form-group">
                            <label>Số điện thoại <span className="text-danger">*</span></label>
                            <InputPhone isError={distributorStore.errors.phone} value={distributorStore.dataRequest.phone}
                                   onChange={(e: any) => distributorStore.dataRequest.phone = e}
                                   placeholder="Nhập số điện thoại"/>
                        </div>
                        <div className="form-group">
                            <label>Địa chỉ</label>
                            <div className="input-group">
                                <input type="text" className="form-control form-control-lg"
                                       value={distributorStore.dataRequest.address}
                                       onChange={(e: any) => distributorStore.dataRequest.address = e.currentTarget.value}
                                       placeholder="Nhập địa chỉ nhà phân phối.."/>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn send-request"
                                onClick={() => distributorStore.addEdit()} disabled={distributorStore.isLoadingBt}>Hoàn thành
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default observer(AddEditDistributor);