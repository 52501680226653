/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { upgradePackageStore } from '../UpgradePackageStore';
import { observer } from 'mobx-react';
import { dateUtils } from '../../../common/utils/DateUtils';
import { convertPrice } from '../../../common/utils/Utils';
import SVG from "react-inlinesvg";
import { useTranslation } from 'react-i18next';
import TextOverflowEllipsis from '../../../common/component/TextOverflowEllipsis';

function PackageInUse() {
    const { t } = useTranslation();

    let { packageFarm, packageQRCodeUsed, unlimitedUser, userUsed, limitUser } = upgradePackageStore.farmPackageBuyInfo || {}
    return (
        <div className="modal fade" id="package_in_use_modal" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <img src="/assets/images/detail_frame.png" alt="" className='detail_frame' />
                <div className="modal-content border-0 p-12px">
                    <div className="modal-header border-0 p-0 pb-10px">
                        <span className="color-00953D fs-20px fw-600">Thông tin gói đang sử dụng</span>
                        <button type="button" id="close_package_in_use_modal" className="close d-none" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body p-0 d-flex flex-column align-items-center gap-10px">
                        <div className='d-flex w-100 align-items-center justify-content-between'>
                            <div className='d-flex flex-shrink-0'>
                                <SVG src="/assets/ico/receipt-item.svg" className='pr-5px w-24px h-24px' />
                                <span className='line-height-24px'>Tên gói</span>
                            </div>
                            <TextOverflowEllipsis className='pl-5px fw-600 line-height-24px text-end' value={packageFarm?.packageData?.name} />
                        </div>
                        <div className='d-flex w-100 align-items-center justify-content-between'>
                            <div className='d-flex'>
                                <SVG src="/assets/ico/profile-2user.svg" className='pr-5px w-24px h-24px' />
                                <span className='line-height-24px'>Người dùng</span>
                            </div>
                            <div className='fw-600'>
                                {
                                    unlimitedUser
                                        ? <span>Không giới hạn</span>
                                        : <>
                                            <span className='color-0089D6'>{convertPrice(userUsed)}</span>/
                                            <span>{convertPrice(limitUser)}</span>
                                        </>
                                }
                            </div>
                        </div>
                        <div className='d-flex w-100 align-items-center justify-content-between'>
                            <div className='d-flex'>
                                <SVG src="/assets/ico/scan-barcode.svg" className='pr-5px w-24px h-24px' />
                                <span className='line-height-24px'>Số mã QR theo lô</span>
                            </div>
                            <div className='fw-600'>
                                {
                                    (packageFarm?.packageData?.exportQrBatchUnlimited)
                                        ? <span>Không giới hạn</span>
                                        : <>
                                            <span className='color-0089D6'>{convertPrice(packageQRCodeUsed?.quantityExportBatch || 0)}</span>/
                                            <span>{convertPrice((Number(packageQRCodeUsed?.remainingExportBatch || 0) + Number(packageQRCodeUsed?.quantityExportBatch || 0)) || 0)}</span>
                                        </>
                                }
                            </div>
                        </div>
                        <div className='d-flex w-100 align-items-center justify-content-between'>
                            <div className='d-flex'>
                                <SVG src="/assets/ico/scan.svg" className='pr-5px w-24px h-24px' />
                                <span className='line-height-24px'>Số mã QR theo sản phẩm</span>
                            </div>
                            <div className='fw-600'>
                                <span className='color-0089D6'>{convertPrice(packageQRCodeUsed?.quantityExportProduct || 0)}</span>/
                                <span>{convertPrice((Number(packageQRCodeUsed?.remainingExportProduct || 0) + Number(packageQRCodeUsed?.quantityExportProduct || 0)) || 0)}</span>
                            </div>
                        </div>
                        <div className='d-flex w-100 align-items-center justify-content-between'>
                            <div className='d-flex'>
                                <SVG src="/assets/ico/calendar-tick.svg" className='pr-5px w-24px h-24px' />
                                <span className='line-height-24px'>Hạn sử dụng</span>
                            </div>
                            <span className='fw-600'>{dateUtils.formatDate(packageFarm?.expirationTime || null)}</span>
                        </div>

                        <div className='package-description'>
                            <div className='d-flex align-items-center'>
                                <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.feature")}</span>
                            </div>
                            <div className='d-flex align-items-center'>
                                {(packageFarm?.packageData?.unlimitedUser || packageFarm?.packageData?.limitUser)
                                    ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                    : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                                {
                                    packageFarm?.packageData?.unlimitedUser
                                        ? <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.unlimitedUser")}</span>
                                        : <span className='pl-8px fs-14px text-overflow-ellipsis'>{`${packageFarm?.packageData?.limitUser || 0} ${t("packageDescriptions.limitUser")}`}</span>
                                }
                            </div>
                            <div className='d-flex align-items-center'>
                                {packageFarm?.packageData?.exportQrBatch
                                    ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                    : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                                <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.exportQrBatch")}</span>
                            </div>
                            <div className='d-flex align-items-center'>
                                {packageFarm?.packageData?.exportQrBatchUnlimited || (!packageFarm?.packageData?.exportQrBatchUnlimited && packageFarm?.packageData?.giftQRCodeExportBatch?.qrCodeLimit)
                                    ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                    : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                                {!packageFarm?.packageData?.exportQrBatchUnlimited && packageFarm?.packageData?.giftQRCodeExportBatch?.qrCodeLimit
                                    ? <span className='pl-8px fs-14px text-overflow-ellipsis'>{`Tặng kèm ${packageFarm?.packageData?.giftQRCodeExportBatch?.qrCodeLimit || 0} mã QR theo lô`}</span>
                                    : <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.exportQrBatchUnlimited")}</span>}
                            </div>
                            <div className='d-flex align-items-center'>
                                {packageFarm?.packageData?.exportQRProduct
                                    ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                    : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                                <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.exportQRProduct")}</span>
                            </div>
                            <div className='d-flex align-items-center'>
                                {packageFarm?.packageData?.exportQRProduct
                                    ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                    : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                                <span className='pl-8px fs-14px text-overflow-ellipsis'>{`Tặng kèm ${packageFarm?.packageData?.giftQRCodeExportProduct?.qrCodeLimit || 0} mã QR theo sản phẩm`}</span>
                            </div>
                            <div className='d-flex align-items-center'>
                                {packageFarm?.packageData?.exportExcel
                                    ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                    : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                                <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.exportExcel")}</span>
                            </div>
                            <div className='d-flex align-items-center'>
                                {packageFarm?.packageData?.fraudAlert
                                    ? <img src={`/assets/ico/ico_check_cricle.svg`} alt="" width={16} className='pt-1px' />
                                    : <img src={`/assets/ico/ico_close_cricle.svg`} alt="" width={16} className='pt-1px' />}
                                <span className='pl-8px fs-14px text-overflow-ellipsis'>{t("packageDescriptions.fraudAlert")}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(PackageInUse)