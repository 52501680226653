import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu, Space } from 'antd';
import './ActionTable.scss';

interface IAction {
    name: string;
    type: "add" | "history" | "permission" | "edit" | "note" | "download" |
    "detail" | "delete" | "print" | "success" | "cancel" | "lock" |
    "open" | "update" | "change" | "verify" | "unverify" | "allocation" |
    "transport" | "stop" | "reimbursement" | "transmit" | "implementer" | "explanation" | "duplicate" | "reject" | "approve" | "paymentApproval";
    url?: string;
    icon?: string;
    isHide?: boolean;
    loading?: boolean;
    dataTarget?: string;
    action?: () => void;
    state?: any;
}

interface IProps {
    id?: any;
    title?: string;
    isHide?: boolean;
    iconHorizontal?: boolean;
    style?: React.CSSProperties;
    isIconWhite?: boolean;
    data: IAction[];
}

const returnIcon = (type: string) => {
    const iconMap: { [key: string]: string } = {
        transmit: "ico_transmit.svg",
        reimbursement: "ico_reimbursement.svg",
        add: "ico_add.svg",
        history: "ico_history.svg",
        edit: "ico_edit.svg",
        permission: "ico_permission.svg",
        note: "ico_note.svg",
        download: "ico_download.svg",
        detail: "ico_detail.svg",
        success: "ico_check.svg",
        verify: "ico_verify.svg",
        unverify: "ico_unVerify.svg",
        cancel: "ico_cancel.svg",
        allocation: "ico_allocation.svg",
        change: "ico_change.svg",
        delete: "ico_trash.svg",
        print: "ico_printer.svg",
        transport: "ico_truck.svg",
        stop: "ico_pause.svg",
        explanation: "sort-amount-up.svg",
        duplicate: "document-copy.svg",
        reject: "ico_reject.svg",
        approve: "ico_approve.svg",
        paymentApproval: "card-tick.svg",
    };
    return iconMap[type] || "default-icon.svg";
};

const ActionTable: React.FC<IProps> = ({ isHide, data, isIconWhite, style, iconHorizontal }) => {
    const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
    const renderMenuItem = useCallback((item: IAction, index: number) => {

        const handleClick = () => {
            if (item.action) item.action();
            setDropdownVisible(false); // Đóng dropdown sau khi click vào action
        };

        const commonProps = {
            onClick: handleClick,
            disabled: item.loading,
            className: `items ${index > 0 ? 'item_border' : ''} ${item.type}`,
        };

        const content = (
            <>
                <img width={20} height={20} src={item.icon || `/assets/ico/action/${returnIcon(item.type)}`}
                    className="icon-action" title={item.name} alt="" />
                <p>{item.name}</p>
            </>
        );

        if (item.url) {
            return (
                <Link to={item.url} {...commonProps} key={index} state={item?.state}>
                    {content}
                </Link>
            );
        }

        if (item.dataTarget) {
            return (
                <button data-toggle="modal" data-target={`#${item.dataTarget}`}{...commonProps} key={index}>
                    {content}
                </button>
            );
        }

        return (
            <button {...commonProps} key={index}>
                {content}
            </button>
        );
    }, []);

    const getIconSrc = useCallback((isIconWhite: boolean | undefined, iconHorizontal: boolean | undefined): string => {
        if (isIconWhite) return "ico_dot_white.svg";
        if (iconHorizontal) return "ico_dot_horizontal.svg";
        return "ico_dot.svg";
    }, []);

    if (isHide) return null;

    const filteredData = data.filter(item => !item.isHide);

    if (filteredData.length === 0) return null;

    const antMenu = (
        <Menu className="dropdown_wrapper">
            {filteredData?.map(renderMenuItem)}
        </Menu>
    );

    return (
        <div className="d-flex justify-content-center">
            <div className="action_wrapper" style={data?.filter((i: any) => !(i.isHide)).length <= 0 ? { display: 'none' } : {}}>
                <Dropdown
                    overlay={antMenu}
                    trigger={['click']}
                    visible={dropdownVisible}
                    onVisibleChange={setDropdownVisible}
                >
                    <a onClick={(e: any) => e.preventDefault()}>
                        <Space style={style} className="action_icon__wrapper cursor_pointer">
                            <img src={`/assets/ico/action/${getIconSrc(isIconWhite, iconHorizontal)}`} alt="" />
                        </Space>
                    </a>
                </Dropdown>
            </div>
        </div>
    );
};

export default React.memo(ActionTable);
