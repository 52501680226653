import { getRequest, IApiResponse, patchRequest } from "../../common/helpers/RequestHelper";
import { stringParams } from "../../common/utils/Utils";
import { historyOrderFarmStore } from "./HistoryOrderFarmStore";


class HistoryOrderFarmService {

    public getHistoryOrderFarm(paymentState?: any): Promise<IApiResponse> {
        console.log(paymentState)
        let searchObject = {
            page: historyOrderFarmStore.page,
            size: 10,
            farmId: localStorage.getItem('farmId'),
            paymentState: paymentState ? paymentState : historyOrderFarmStore.paymentState || null,
        }
        return getRequest(`/v1/package_order_transaction${stringParams(searchObject)}`)
    }

    public getDetail(id: any): Promise<IApiResponse> {
        return getRequest(`/v1/package_order_transaction/${id}`)
    }

    public changeStatus(id: number | string, status: string): Promise<IApiResponse> {
        return patchRequest(`/v1/package_order_transaction/${id}/change-status-order`, { status: status, note: '' })
    }
}

export const historyOrderFarmService = new HistoryOrderFarmService();