import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import LoginComponent from "../auth/login/LoginComponent";
import ProfileComponent from "../profile/component/ProfileComponent";
import SetupComponent from "../setup/SetupComponent";
import ProcedureComponent from '../procedure/ProcedureComponent';
import InfoComponent from "../info/InfoComponent"
import ProcedureJobComponent from "../procedure/procedureJob/ProcedureJobComponent";
import StorageService from "../../common/service/StorageService";
import PlanComponent from "../planning/PlanComponent";
import DiaryComponent from "../diary/DiaryComponent";
import ReportComponent from "../report/ReportComponent";
import UsersComponent from "../admin/users/UsersComponent";
import UserLockComponent from "../admin/users/UserLockComponent";
import UserActiveComponent from "../admin/users/UserActiveComponent";
import Dashboard from "../dashboard/Dashboard";
import {profileStore} from "../profile/ProfileStore";
import RoleComponent from "../admin/role/RoleComponent";
import UpdateRole from "../admin/role/components/UpdateRole";
import RoleDefault from "../admin/role/RoleDefault";
import MapLandDetail from "../setup/land/map/MapLandDetail";
import QuestionComponent from "../question/QuestionComponent";
import QuestionDetail from "../question/QuestionDetail";
import StagePage from "../procedure/stage/StagePage";
import TrackingPage from "../tracking/TrackingPage";
import InfoTracking from "../infoTracking/InfoTracking";
import TrackingDetailPage from "../tracking/trackingDetail/TrackingDetailPage";
import PreviewTracking from "../infoTracking/PreviewTracking";
import InfoTrackingBatchCode from "../infoTracking/InfoTrackingBatchCode";
import {observer} from "mobx-react";
import TrackingSeafoodPage from "../trackingSeaFood/TrackingSeafoodPage";
import TrackingSeafoodDetailPage from "../trackingSeaFood/trackingDetail/TrackingSeafoodDetailPage";
import InfoTrackingSeafood from "../infoTrackingSeafood/InfoTrackingSeafood";
import InfoTrackingBatchCodeSeafood from "../infoTrackingSeafood/InfoTrackingBatchCodeSeafood";
import PreviewTrackingSeafood from "../infoTrackingSeafood/PreviewTrackingSeafood";
import PlanDetail from "../planning/planDetail/PlanDetail";
import ManagerInformationPage from "../manager/information/ManagerInformationPage";
import DetailFarmInformation from "../manager/information/components/detailFarmInformation/DetailFarmInformation";
import AccountManagerPage from "../admin/accountManager/AccountManagerPage";
import ManagerProductPage from "../manager/product/ManagerProductPage";
import ManagerTrackingPage from "../manager/tracking/ManagerTrackingPage";
import DetailTrackingPage from "../manager/tracking/components/DetailTrackingPage";
import WarehousePage from "../warehouse/WarehousePage";
import ImportExportHistory from "../warehouse/ImportExportHistory";
import NoContent from "../../common/component/NoContent";
import ManagerDistributionPage from "../manager/distribution/ManagerDistribution";
import BlogPage from "../blog/BlogPage";
import AddEditBlog from "../blog/components/AddEditBlog";
import TrackingLivestockPage from "../trachkingLivestock/TrackingLivestockPage";
import TrackingLivestockDetailPage from "../trachkingLivestock/trackingDetail/TrackingLivestockDetailPage";
import PreviewTrackingLivestock from "../infoTrackingLivestock/PreviewTrackingLivestock";
import InfoTrackingLivestock from "../infoTrackingLivestock/InfoTrackingLivestock";
import InfoTrackingBatchCodeLivestock from "../infoTrackingLivestock/InfoTrackingBatchCodeLivestock";
import HistoryOrderFarmPage from '../historyOrderFarm/HistoryOrderFarmPage';
import BuyPackage from '../upgradePackage/components/BuyPackage';
import BankInformation from '../upgradePackage/components/BankInformation';
import ApprovedPackagePage from "../admin/approvedPackage/ApprovedPackagePage";
import PackagePage from "../admin/package/PackagePage";
import QrCodePackagePage from "../admin/packageQrCode/QrCodePackagePage";

const Redirect = () => {
    const isAdmin = profileStore.roleUser === 'admin';
    const isManager = profileStore.roleUser === 'manager';
    const adminRoutes = (
        <Routes>
            <Route path="/profile" element={<ProfileComponent/>}/>
            <Route path="/cms/users" element={<UsersComponent/>}/>
            <Route path="/cms/account-manager" element={<AccountManagerPage/>}/>
            <Route path="/cms/users/active" element={<UserActiveComponent/>}/>
            <Route path="/cms/users/lock" element={<UserLockComponent/>}/>
            <Route path="/cms/permission" element={<RoleComponent/>}/>
            <Route path="/cms/permission-default" element={<RoleDefault/>}/>
            <Route path="/cms/permission/:slug/:id" element={<UpdateRole/>}/>
            <Route path="/cms/permission/:slug/:id" element={<UpdateRole/>}/>
            <Route path="/cms/setup_package/omfarm" element={<PackagePage/>}/>
            <Route path="/cms/setup_package/qrcode-batch" element={<QrCodePackagePage/>}/>
            <Route path="/cms/setup_package/qrcode-product" element={<QrCodePackagePage/>}/>
            <Route path="/cms/approve-package" element={<ApprovedPackagePage/>}/>
            <Route path="*" element={<Navigate to="/cms/users" replace/>}/>
        </Routes>
    );
    const managerRoutes = (
        <Routes>
            <Route path="/manager/information" element={<ManagerInformationPage/>}/>
            <Route path="/manager/information/:slug/:id" element={<DetailFarmInformation/>}/>
            <Route path="/manager/product" element={<ManagerProductPage/>}/>
            <Route path="/manager/truy-xuat" element={<ManagerTrackingPage/>}/>
            <Route path="/manager/truy-xuat/:slug/:id" element={<DetailTrackingPage/>}/>
            <Route path="/manager/nha-phan-phoi" element={<ManagerDistributionPage/>}/>
            <Route path="/manager/bao-cao" element={<NoContent/>}/>
            <Route path="*" element={<Navigate to="/manager/information" replace/>}/>
        </Routes>
    );

    const userRoutes = (
        <Routes>
            <Route path={'/'} element={<Dashboard/>}/>
            <Route path="/login" element={<LoginComponent/>}/>
            <Route path="/cms" element={<LoginComponent/>}/>
            <Route element={<ProtectedRoute/>}>
                <Route path="/" element={<Dashboard/>}/>
            </Route>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/farm" element={<InfoComponent/>}/>
            <Route path="/profile" element={<ProfileComponent/>}/>
            <Route path="/setup" element={<SetupComponent/>}/>
            <Route path="/warehouse" element={<WarehousePage/>}/>
            <Route path="/warehouse/history-log" element={<ImportExportHistory/>}/>
            <Route path="/maps" element={<MapLandDetail/>}/>
            <Route path="/map/:slug/:id" element={<MapLandDetail/>}/>
            <Route path="/procedure" element={<ProcedureComponent/>}/>
            <Route path="/procedure/stage" element={<StagePage/>}/>
            <Route path="/procedure-detail/:slug/:id" element={<ProcedureJobComponent/>}/>
            <Route path="/planning" element={<PlanComponent/>}/>
            <Route path="/planning-detail/:slug/:id" element={<PlanDetail/>}/>
            <Route path="/diary" element={<DiaryComponent/>}/>
            
            <Route path="/buy-package" element={<BuyPackage/>}/>
            <Route path="/buy-package/:packageId" element={<BuyPackage/>}/>
            <Route path="/bank-information/:packageOrderId" element={<BankInformation/>}/>
            <Route path="/history-order-farm" element={<HistoryOrderFarmPage/>}/>

            <Route path="/tracking-seafood" element={<TrackingSeafoodPage/>}/>
            <Route path="/tracking-seafood-batch/:slug/:id" element={<TrackingSeafoodDetailPage/>}/>
            <Route path="/xem-ket-qua-truy-xuat-hai-san/:slug/:id" element={<PreviewTrackingSeafood/>}/>
            <Route path="/truy-xuat-lo-hai-san/:slug/:trackingId/:trackingBatchId" element={<InfoTrackingBatchCodeSeafood/>}/>
            <Route path="/truy-xuat-hai-san/:slug/:batchCode" element={<InfoTrackingSeafood/>}/>

            <Route path="/tracking-livestock" element={<TrackingLivestockPage/>}/>
            <Route path="/tracking-livestock-batch/:slug/:id" element={<TrackingLivestockDetailPage/>}/>
            <Route path="/xem-ket-qua-truy-xuat-chan-nuoi/:slug/:id" element={<PreviewTrackingLivestock/>}/>
            <Route path="/truy-xuat-lo-chan-nuoi/:slug/:trackingId/:trackingBatchId" element={<InfoTrackingBatchCodeLivestock/>}/>
            <Route path="/truy-xuat-chan-nuoi/:slug/:batchCode" element={<InfoTrackingLivestock/>}/>

            <Route path="/tracking" element={<TrackingPage/>}/>
            <Route path="/tracking-detail/:slug/:id" element={<TrackingDetailPage/>}/>
            <Route path="/xem-ket-qua-truy-xuat/:slug/:id" element={<PreviewTracking/>}/>
            <Route path="/truy-xuat/:slug/:batchCode" element={<InfoTracking/>}/>
            <Route path="/truy-xuat-lo/:slug/:trackingId/:trackingBatchId" element={<InfoTrackingBatchCode/>}/>
            <Route path="/report" element={<ReportComponent/>}/>
            <Route path="/question" element={<QuestionComponent/>}/>
            <Route path="/question/:slug/:id" element={<QuestionDetail/>}/>
            <Route path="/blog" element={<BlogPage/>}/>
            <Route path="/add-blog" element={<AddEditBlog/>}/>
            <Route path="/update-blog/:slug/:id" element={<AddEditBlog/>}/>
            <Route path="*" element={<Navigate to="/" replace/>}/>
        </Routes>
    );

    return isAdmin ? adminRoutes : isManager ? managerRoutes : userRoutes;
}

const ProtectedRoute = ({children}: any) => {
    if (!StorageService.getToken()) {
        return <Navigate to="/login" replace/>;
    }
    return children;
};

export default observer(Redirect);