export default class Constants {
    public static TOKEN_EXPIRE_DAYS: number = 16;
    public static TOKEN_NAME: string = "token";
    public static REFRESH_TOKEN: string = "refreshToken";
    public static API_URL: String = (window as any).API_DOMAIN;
    public static MAX_SIZE: any = 2000;
}

export const PermissionConst = {
    equipmentAdd: "equipment_add",
    equipmentUpdate: "equipment_update",
    equipmentDelete: "equipment_delete",
    equipmentView: "equipment_view",
    diaryUpdate: "diary_update",
    fertilizerAdd: "fertilizer_add",
    fertilizerUpdate: "fertilizer_update",
    fertilizerDelete: "fertilizer_delete",
    fertilizerView: "fertilizer_view",
    ingredientAdd: "ingredient_add",
    ingredientUpdate: "ingredient_update",
    ingredientDelete: "ingredient_delete",
    ingredientView: "ingredient_view",
    jobAdd: "job_add",
    jobUpdate: "job_update",
    jobView: "job_view",
    jobDelete: "job_delete",
    landAdd: "land_add",
    landUpdate: "land_update",
    landView: "land_view",
    landDelete: "land_delete",
    laborAdd: "labor_add",
    laborUpdate: "labor_update",
    laborView: "labor_view",
    laborDelete: "labor_delete",
    purchaseHistoryAdd: "purchase_history_add",
    purchaseHistoryUpdate: "purchase_history_update",
    purchaseHistoryDelete: "purchase_history_delete",
    purchaseHistoryView: "purchase_history_view",
    medicineAdd: "medicine_add",
    medicineUpdate: "medicine_update",
    medicineDelete: "medicine_delete",
    medicineView: "medicine_view",
    distributorAdd: "distributor_add",
    distributorUpdate: "distributor_update",
    distributorDelete: "distributor_delete",
    distributorView: "distributor_view",
    planAdd: "plan_add",
    planUpdate: "plan_update",
    planDelete: "plan_delete",
    planView: "plan_view",
    procedureAdd: "procedure_add",
    procedureUpdate: "procedure_update",
    procedureDelete: "procedure_delete",
    procedureView: "procedure_view",
    productAdd: "product_add",
    productUpdate: "product_update",
    productDelete: "product_delete",
    productView: "product_view",
    trackingUpdate: "tracking_update",
    reportView: "report_view",
    farmView: "farm_view",
    farmUpdate: "farm_update",
    answerQuestion: "answer_question",
    suppliesInventory: "supplies_inventory",
    farmBlogAdd: "farm_blog_add",
    farmBlogView: "farm_blog_view",
    farmBlogUpdate: "farm_blog_update",
    farmBlogDelete: "farm_blog_delete",
}

export const REGEX = {
    PHONE: /^\d{10,}$/
  }