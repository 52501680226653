import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Popover, Tag } from "antd";
import { approvedPackageStore, INIT_PARAMS } from "./ApprovedPackageStore";
import PopupApprovePackage from "./components/PopupApprove";
import PopupRejectPackage from "./components/PopupReject";
import Loading from "../../../common/component/Loading";
import { sttPage } from "../../../common/utils/Utils";
import { dateUtils } from "../../../common/utils/DateUtils";
import { numberUtil } from "../../../common/utils/NumberUtil";
import NoContent from "../../../common/component/NoContent";
import Paginate from "../../../common/component/Paginate";
import FilterComponent from "../../../common/component/filter/FilterComponent";
import PopupCancelPackage from "./components/PopupCancel";
import DetailApprovePackage from "./components/DetailApprovePackage";
import FilterApprovedPackage from './components/FilterApprovedPackage';
import ActionTable from '../../../common/component/actionTable/ActionTable';


export const convertStatePayment = (state: string) => {
    switch (state) {
        case "PAID":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#efffef"
                style={{ width: 115, color: '#158212', borderRadius: 12 }}>Đã thanh toán</Tag>
        case "REFUND":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#F693201A"
                style={{ width: 115, color: '#F69320', borderRadius: 12 }}>Hoàn tiền</Tag>
        case "FAILED":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#FFEEEF"
                style={{ width: 115, color: '#E3000F', borderRadius: 12 }}>Từ chối thanh toán</Tag>
        case "UNPAID":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#E6E6E6"
                style={{ width: 115, color: '#808089', borderRadius: 12 }}>Chưa thanh toán</Tag>
    }
}

export const convertState = (state: string) => {
    switch (state) {
        case "APPROVED":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#efffef"
                style={{ width: 82, color: '#158212', borderRadius: 12 }}>Đã duyệt</Tag>
        case "REJECTED":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#FFEEEF"
                style={{ width: 82, color: '#E3000F', borderRadius: 12 }}>Từ chối</Tag>
        case "PENDING":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#E6E6E6"
                style={{ width: 82, color: '#808089', borderRadius: 12 }}>Chờ duyệt</Tag>
        case "CANCEL":
            return <Tag bordered={false} className="fs-14 text-center pe-2 ps-2" color="#1F3A581A"
                style={{ width: 82, color: '#1F3A58', borderRadius: 12 }}>Hủy</Tag>
    }
}


const ApprovedPackagePage = () => {

    const handlePageClick = async (data: any) => {
        approvedPackageStore.page = data.selected
        await approvedPackageStore.getApprovedPackage()
    }

    useEffect(() => {
        approvedPackageStore.getApprovedPackage().then()

        return () =>{
            approvedPackageStore.clearFilter()
        }
    }, [])

    const callApi = () => {
        approvedPackageStore.page = 0
        approvedPackageStore.getApprovedPackage().then()
    }

    let dataFilter: any[] = [
        {
            label: "Tìm kiếm",
            type: 'search',
            placeholder: 'Nhập từ khóa',
            value: approvedPackageStore.params?.code,
            onChange: (e: any) => approvedPackageStore.params.code = e,
            callback: () => callApi()
        },
    ]


    return (
        <div className="enterprise_page">
            <div className="d-flex mb-4 align-items-center">

                <FilterComponent
                    permissionCode
                    data={dataFilter}
                    componentIcon={
                        <Popover
                            content={
                                <FilterApprovedPackage
                                    handleClose={() => approvedPackageStore.isOpenFilter = false}
                                    params={approvedPackageStore.params}
                                    callbackClear={() => {
                                        approvedPackageStore.params = { ...INIT_PARAMS }
                                        callApi();
                                    }}
                                    callback={callApi}
                                />}
                            trigger="click"
                            open={approvedPackageStore.isOpenFilter}
                            placement="bottomLeft"
                            overlayStyle={{ marginLeft: '-10px' }}
                            onOpenChange={(open) => approvedPackageStore.isOpenFilter = open}
                        >
                            <div
                                className="action_filter"
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                <img className='m-0' width={20} src="/assets/ico/filter.svg" alt="" />
                                {approvedPackageStore.lengthAction ? <div className='number_action_filter'>{approvedPackageStore.lengthAction}</div> : ""}
                            </div>
                        </Popover>
                    }
                />
            </div>
            <div id='kt_ecommerce_sales_table_wrapper' className='dataTables_wrapper dt-bootstrap4 no-footer'>
                {approvedPackageStore.isLoading ? <Loading /> : <div>
                    <div className='table-responsive calc-max-h-vh-270px overflow-auto'>
                        {approvedPackageStore.listApprovedPackage && approvedPackageStore.listApprovedPackage.length > 0 ?
                            <table className='table align-middle table-hover table-rounded table-striped border gy-7 gs-7 table-sticky-header'>
                                <thead>
                                    <tr className='fw-bold fs-6 text-gray-800 border-bottom border-gray-200'>
                                        <th className="text-center">STT</th>
                                        <th className="text-center">Ngày yêu cầu</th>
                                        <th>Mã thanh toán</th>
                                        <th>Người gửi</th>
                                        <th className="text-end">Tổng tiền</th>
                                        <th className="text-center">Trạng thái thanh toán</th>
                                        <th className="text-center">Trạng thái</th>
                                        <th className="text-center">Ngày duyệt</th>
                                        <th>Thao tác</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {approvedPackageStore.listApprovedPackage.map((item: any, i: number) => {
                                        return (
                                            <tr key={i}>
                                                <td className="text-center">{sttPage(approvedPackageStore.page, i)}</td>
                                                <td className="text-center">{dateUtils.formatDate(item.createdAt)}</td>
                                                <td>{item.code}</td>
                                                <td>{item.createdByUser?.name}</td>
                                                <td className="text-end">{numberUtil.number_format(item.totalPrice)} đ</td>
                                                <td className="text-center">
                                                    {convertStatePayment(item.paymentState)}
                                                </td>
                                                <td className="text-center">
                                                    {convertState(item.status)}
                                                </td>
                                                <td className="text-center">{dateUtils.formatDate(item.approveAt)}</td>
                                                <td >
                                                    <ActionTable
                                                        iconHorizontal
                                                        style={{ background: "white" }}
                                                        data={[
                                                            {
                                                                name: "Chi tiết",
                                                                type: "detail",
                                                                dataTarget: "detail_approve_package",
                                                                action: () => approvedPackageStore.getDetail(item.id),
                                                            },
                                                            {
                                                                name: "Chưa thanh toán",
                                                                type: "reject",
                                                                dataTarget: "popup_reject",
                                                                isHide: !(item.paymentState === "UNPAID" && item.status === "PENDING"),
                                                                action: () => {
                                                                    approvedPackageStore.isPaymentState = true;
                                                                    approvedPackageStore.id = item.id
                                                                },
                                                            },
                                                            {
                                                                name: "Duyệt thanh toán",
                                                                type: "paymentApproval",
                                                                dataTarget: "popup_approve",
                                                                isHide: !(item.paymentState === "UNPAID" && item.status === "PENDING"),
                                                                action: () => {
                                                                    approvedPackageStore.isPaymentState = true;
                                                                    approvedPackageStore.id = item.id
                                                                },
                                                            },
                                                            {
                                                                name: "Duyệt",
                                                                type: "approve",
                                                                isHide: !(item.paymentState === "PAID" && item.status === "PENDING"),
                                                                dataTarget: "popup_approve",
                                                                action: () => approvedPackageStore.id = item.id,
                                                            },
                                                            {
                                                                name: "Từ chối",
                                                                type: "reject",
                                                                dataTarget: "popup_reject",
                                                                isHide: !(item.paymentState === "PAID" && item.status === "PENDING"),
                                                                action: () => approvedPackageStore.id = item.id,
                                                            },
                                                        ]}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table> : <NoContent />}
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-4">
                        <Paginate changePage={handlePageClick} totalPage={approvedPackageStore.totalPage}
                            currentPage={approvedPackageStore.page} />
                    </div>
                    <DetailApprovePackage />
                    <PopupCancelPackage />
                    <PopupApprovePackage />
                    <PopupRejectPackage />
                </div>}
            </div>
        </div>
    )
}

export default observer(ApprovedPackagePage)